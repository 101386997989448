import React, { useEffect } from "react";
import {
  Form,
  Col as BootstrapCol,
  Row as BootstrapRow,
} from "react-bootstrap";
import { useTranslation } from "react-i18next";
import useArmenianStatesData from "../../hooks/useArmenianStatesData";

const AddEditAddress = ({
  values = {},
  handleChange = () => {},
  setValues = () => {},
  hideCheckbox,
  touched = {},
  editingData,
  errors = {},
  checkBoxLabel,
  showCommentForm,
}) => {
  const { t } = useTranslation();
  const ARMENIA_PROVINCES = useArmenianStatesData();

  useEffect(() => {
    let initialValues = {};
    if (editingData) {
      initialValues = {
        id: editingData.id,
        firstName: editingData.firstName,
        lastName: editingData.lastName || "",
        address: editingData.address || "",
        phone: editingData.phone || "",
        city: editingData.city || t("states.yerevan"),
        state: editingData.state || t("states.yerevan"),
        zip: editingData.zip || "",
        comment: editingData.comment || "",
        checkbox: editingData.isDefault || false,
      };
      setValues(initialValues);
    }
  }, [editingData, setValues, t]);

  return (
    <Form>
      <BootstrapRow className="mb-3">
        <Form.Group as={BootstrapCol} controlId="formGridEmail">
          <Form.Label>{t("address.firstName")}*</Form.Label>
          <Form.Control
            type="text"
            name="firstName"
            placeholder={t("address.enterName")}
            value={values.firstName}
            onChange={handleChange}
            isValid={touched.firstName && !errors.firstName}
            isInvalid={!!errors.firstName}
          />
          <Form.Control.Feedback type="invalid">
            {errors.firstName}
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group as={BootstrapCol} controlId="formGridPassword">
          <Form.Label>{t("address.lastName")}</Form.Label>
          <Form.Control
            type="text"
            name="lastName"
            placeholder={t("address.enterLastName")}
            value={values.lastName}
            onChange={handleChange}
          />
        </Form.Group>
      </BootstrapRow>

      <Form.Group className="mb-3" controlId="formGridAddress">
        <Form.Label>{t("address.title")}*</Form.Label>
        <Form.Control
          name="address"
          placeholder={t("address.addressExample")}
          value={values.address}
          onChange={handleChange}
          isValid={touched.address && !errors.address}
          isInvalid={!!errors.address}
        />
        <Form.Control.Feedback type="invalid">
          {errors.address}
        </Form.Control.Feedback>
      </Form.Group>

      <Form.Group className="mb-3" controlId="formGridPhone">
        <Form.Label>{t("address.phone")}*</Form.Label>
        <Form.Control
          name="phone"
          type="tel"
          placeholder={t("address.enterPhone")}
          value={values.phone}
          onChange={handleChange}
          isValid={touched.phone && !errors.phone}
          isInvalid={!!errors.phone}
        />
        <Form.Control.Feedback type="invalid">
          {errors.phone}
        </Form.Control.Feedback>
      </Form.Group>

      <BootstrapRow className="mb-3">
        <Form.Group as={BootstrapCol} controlId="formGridCity">
          <Form.Label>{t("address.city")}*</Form.Label>
          <Form.Control
            name="city"
            type="text"
            placeholder={t("address.enterCity")}
            value={values.city}
            onChange={handleChange}
            isValid={touched.city && !errors.city}
            isInvalid={!!errors.city}
          />
          <Form.Control.Feedback type="invalid">
            {errors.city}
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group as={BootstrapCol} controlId="formGridState">
          <Form.Label>{t("address.state")}*</Form.Label>
          <Form.Select
            as="select"
            name="state"
            value={values.state}
            onChange={handleChange}
          >
            {ARMENIA_PROVINCES.map((provinceName, i) => (
              <option key={i}>{provinceName}</option>
            ))}
          </Form.Select>
        </Form.Group>

        <Form.Group as={BootstrapCol} controlId="formGridZip">
          <Form.Label>{t("address.zipCode")}</Form.Label>
          <Form.Control
            name="zip"
            value={values.zip}
            onChange={handleChange}
            placeholder={t("address.enterZipCode")}
          />
        </Form.Group>
      </BootstrapRow>

      {showCommentForm && (
        <Form.Group className="mb-3" controlId="formGridComment">
          <Form.Label>{t("address.comment")}</Form.Label>
          <Form.Control
            name="comment"
            placeholder={t("address.enterComment")}
            value={values.comment}
            onChange={handleChange}
          />
        </Form.Group>
      )}

      {!hideCheckbox && (
        <Form.Group className="mb-3" id="formGridCheckbox">
          <Form.Check
            name="checkbox"
            as="input"
            checked={values.checkbox}
            onChange={handleChange}
            type="checkbox"
            label={checkBoxLabel || t("address.saveAddress")}
          />
        </Form.Group>
      )}
    </Form>
  );
};

export default AddEditAddress;
