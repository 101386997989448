export const WHITE_COLOR = "#ffffff";
export const WHITESMOKE_COLOR = "#F2F2F2";
export const DARK_GRAY_COLOR = "#272A36";
export const GREEN_COLOR = "#2cba5b";
export const RED_COLOR = "#ef233c";
// export const PINK_RED_COLOR = "#f73378";
export const PINK_RED_COLOR = "#f73378";
export const GRAY_COLOR = "#858585";
export const LIGHT_ORANGE = "#fff5ee";
export const LIGHT_BACKGROUND_COLOR = "#f5f3f5";
export const LIGHT_GRADIENT_COLOR = "linear-gradient(135deg, #ffffff, #f4f0ed)";
// export const LIGHT_TO_DARK_GRADIENT =
//   "linear-gradient(0deg, rgba(254, 249, 249, 1), rgba(254, 244, 236, 0.1))";
export const LIGHT_TO_DARK_GRADIENT =
  "linear-gradient(0deg, rgba(51, 34, 20, 0.5), rgba(254, 244, 236, 0))";

export const DARK_BROWN_TEXT_COLOR = "#333333";

// export const LIGHT_BACKGROUND_COLOR = "#fff6f6";
export const BLACK_COLOR = "#00000";
// export const DARK_BACKGROUND_COLOR = "#272A36";
export const DARK_BACKGROUND_COLOR = "#272A36";
