import React from "react";
import styled from "styled-components";
import { formatNumber } from "../../../utils/common";
import { LIGHT_BACKGROUND_COLOR, WHITE_COLOR } from "../../../constants/colors";

const Footer = styled.footer`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: ${WHITE_COLOR};
`;
const Box = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding-right: 8%;
  padding-top: 8px;
  padding-bottom: 8px;
  border-top: 1px solid ${LIGHT_BACKGROUND_COLOR};
`;

const TotalBar = ({ count, price, paperUsageInfo }) => {
  return (
    <Footer>
      <Box>
        {paperUsageInfo &&
          Object.entries(paperUsageInfo).map(([size, count]) => (
            <div className="mx-3" key={size}>
              <strong> {count} :</strong> {size}
            </div>
          ))}
      </Box>
      <Box>
        <div className="mx-2">Orders: {count}</div>
        <div className="mx-4">Avg: {formatNumber(price / count)}</div>
        <div>
          <strong>Total: {formatNumber(price)}</strong>
        </div>
      </Box>
    </Footer>
  );
};

export default TotalBar;
