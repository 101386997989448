import React from "react";
import { Spinner } from "react-bootstrap";
import styled from "styled-components";
import { DARK_GRAY_COLOR } from "../../constants/colors";
import { Span } from "../shared/styledElements/Span";

const LoadingContainer = styled.div`
  display: flex;

  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
`;

const Loading = () => {
  return (
    <LoadingContainer>
      <Spinner animation="border" variant="primary" />
      <Span margin="0 0 0 8px" fontSize="24px" color={DARK_GRAY_COLOR}>
        Loading...
      </Span>
    </LoadingContainer>
  );
};

export default Loading;
