import { GRAY_COLOR, GREEN_COLOR, RED_COLOR } from "./colors";

export const ORDER_STATUS = {
  0: {
    id: 0,
    title: "Payment Pending",
    iconName: "fa fa-clock-o",
    color: GRAY_COLOR,
    text: "Your payment pending, please come back soon",
    titleLabel: "payment.pendingTitle",
    textLabel: "payment.pendingText",
  },
  2: {
    id: 2,
    title: "Payment Success",
    iconName: "fa fa-check-circle",
    color: GREEN_COLOR,
    text: "Congratulations! Your order has been placed",
    titleLabel: "payment.successTitle",
    textLabel: "payment.successText",
  },
  6: {
    id: 6,
    title: "Payment Declined",
    iconName: "fa fa-times-circle",
    color: RED_COLOR,
    text: "Oops! your payment has been declined",
    titleLabel: "payment.declinedTitle",
    textLabel: "payment.declinedText",
  },
  898: {
    color: GRAY_COLOR,
    iconName: "fa fa-times-circle",
    title: "Payment Data not Available",
    text: "Payment data not available",
    titleLabel: "payment.unAvailableTitle",
    textLabel: "payment.unAvailableText",
  },
};
