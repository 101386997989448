import React from "react";
import styled from "styled-components";

const StyledMainWrapper = styled.div`
  margin-top: 60px;
`;

export const MainWrapper = ({ children, ...restProps }) => {
  return <StyledMainWrapper {...restProps}>{children}</StyledMainWrapper>;
};
