import React, { useEffect, useState, useMemo } from "react";
import styled from "styled-components";
import { Formik } from "formik";
import { toast } from "react-toastify";
import { v4 as uuidv4 } from "uuid";
import { Card, Container, Row as BootstrapRow } from "react-bootstrap";
import {
  DARK_BACKGROUND_COLOR,
  GRAY_COLOR,
  PINK_RED_COLOR,
  WHITE_COLOR,
} from "../../constants/colors";
import { useAuth } from "../../contexts/AuthContext";
import { Divider } from "../shared/styledElements/Divider";
import { Heading5 } from "../shared/styledElements/Heading";
import { Flex } from "../shared/styledElements/Flex";
import VerticallyCenteredModal from "../shared/modals/VerticallyCenteredModal";
import DeleteModal from "../shared/modals/DeleteModal";
import AddEditAddress from "./AddEditAddress";
import EmptyContent from "../shared/emptyContent/EmptyContent";
import { scrollToTop } from "../../utils/common";
import { Icon } from "../shared/icons/Icon";
import { db } from "../../firebase";
import Loading from "../Loading/Loading";
import { DEVICES } from "../../constants/layout";
import { useTranslation } from "react-i18next";
import { useAddAddressValidation } from "../shared/validation/useValidation";

const Paper = styled.div`
  @media ${DEVICES.tablet} {
    width: 100%;
    padding: 2px;
  }
  display: flex;
  align-items: center;
  background-color: ${WHITE_COLOR};
  min-height: 400px;
  height: fit-content;
  width: 68%;
  border-radius: 12px;
  box-shadow: 0px 1px 8px 1px ${DARK_BACKGROUND_COLOR}1A;
  padding: 14px;
`;

const StyledContainer = styled(Container)`
  padding-top: 20px;
  padding-bottom: 30px;
`;

const Row = styled(BootstrapRow)`
  display: flex;
  padding: 8px 0;
  font-weight: 500;
`;

const CardLink = styled(Card.Link)`
  @media ${DEVICES.tablet} {
    font-size: 14px;
  }
  color: ${({ color }) => color || ""};
  cursor: pointer;
  &:hover {
    color: ${({ color }) => color || ""};
  }
`;

const AddressListItem = styled.div`
  border: 1px solid ${GRAY_COLOR}50;
  border-radius: 4px;
  margin-bottom: 8px;
  cursor: pointer;
  display: flex;
  height: 50px;
  align-items: center;
  padding-left: 12px;
  overflow: hidden;
  white-space: nowrap;
  &:hover {
    background-color: ${PINK_RED_COLOR};
    color: ${WHITE_COLOR};
  }
`;

const ListItem = styled.div`
  margin-right: 6px;
`;

const AddressBook = ({
  checkoutMode,
  checkoutAddress,
  setCheckoutAddress = () => {},
}) => {
  const { t } = useTranslation();
  const addAddressValidation = useAddAddressValidation();
  const { currentUser } = useAuth();
  const [modalShow, setModalShow] = useState(false);
  const [userDetails, setUserDetails] = useState({});
  const [loading, setLoading] = useState(false);
  const [allAddresses, setAllAddresses] = useState([]);
  const [addressForDelete, setAddressForDelete] = useState(null);
  const [editingAddress, setEditingAddress] = useState(null);

  const addressInitialValues = useMemo(() => {
    return {
      firstName: "",
      lastName: "",
      address: "",
      phone: "",
      city: t("states.yerevan"),
      state: t("states.yerevan"),
      zip: "",
      comment: "",
      checkbox: false,
    };
  }, [t]);

  const user = useMemo(() => currentUser || {}, [currentUser]);

  useEffect(
    () => {
      if (currentUser.email) {
        let newUserDetails = {};

        (async () => {
          setLoading(true);
          const userDb = db.collection("users").doc(currentUser.uid);
          const userDetails = await userDb.get();
          if (!userDetails.exists) {
            toast.error("Error getting data");
          } else {
            newUserDetails = userDetails.data();
          }
          setUserDetails(newUserDetails);
          setAllAddresses(newUserDetails?.addresses || []);
          setLoading(false);
          return userDetails;
        })();
      }

      return userDetails;
    },
    // eslint-disable-next-line
    [currentUser.uid]
  );

  const deleteAddressClick = () => {
    const allAddressesClone = [...allAddresses];
    const newAllAddresses = allAddressesClone.filter(
      (d) => d.id !== addressForDelete
    );
    try {
      db.collection("users").doc(user.uid).set(
        {
          addresses: newAllAddresses,
        },
        { merge: true }
      );
    } catch {
      toast.error("Error adding address");
      setAllAddresses(allAddressesClone);
    }
    setAllAddresses(newAllAddresses);
    setAddressForDelete(null);
  };

  const setDefaultClick = (id) => {
    const allAddressesClone = [...allAddresses];
    const allAddressesWithDisabledDefaults = allAddresses.map((a) => {
      if (a.id === id) return { ...a, isDefault: true };
      return { ...a, isDefault: false };
    });

    try {
      db.collection("users").doc(user.uid).set(
        {
          addresses: allAddressesWithDisabledDefaults,
        },
        { merge: true }
      );
    } catch {
      toast.error("Error adding address");
      setAllAddresses(allAddressesClone);
    }

    setAllAddresses(allAddressesWithDisabledDefaults);
  };

  const handleSubmitClick = async (values) => {
    const addressData = {
      id: editingAddress ? values.id : uuidv4(),
      firstName: values.firstName,
      lastName: values.lastName,
      phone: values.phone,
      address: values.address,
      zip: values.zip,
      city: values.city,
      state: values.state,
      isDefault: values.checkbox,
    };

    const allAddressesWithDisabledDefaults = addressData.isDefault
      ? allAddresses.map((a) => ({
          ...a,
          isDefault: false,
        }))
      : allAddresses;

    const newAllAddresses = [...allAddressesWithDisabledDefaults];
    editingAddress
      ? newAllAddresses.splice(
          newAllAddresses.findIndex((e) => e.id === editingAddress.id),
          1,
          addressData
        )
      : newAllAddresses.unshift(addressData);
    setAllAddresses(newAllAddresses);

    try {
      db.collection("users").doc(user.uid).set(
        {
          addresses: newAllAddresses,
        },
        { merge: true }
      );
    } catch {
      toast.error("Error adding address");
      newAllAddresses.shift(addressData);
      setAllAddresses(newAllAddresses);
    }
    setEditingAddress(null);
    setModalShow(false);
    if (!editingAddress) scrollToTop();
  };

  useEffect(() => {
    if (allAddresses.length) {
      setCheckoutAddress(
        allAddresses.find((d) => !!d.isDefault) || allAddresses[0]
      );
    }
  }, [allAddresses, setCheckoutAddress]);

  if (checkoutMode) {
    const data = allAddresses.find((d) => !!d.isDefault) || allAddresses[0];
    return (
      <Formik
        validationSchema={addAddressValidation}
        onSubmit={(values) => handleSubmitClick(values)}
        initialValues={addressInitialValues}
      >
        {({
          handleSubmit,
          handleChange,
          values,
          touched,
          errors,
          setValues,
          setFieldValue,
        }) => (
          <>
            <VerticallyCenteredModal
              title={
                modalShow === "addEditAddress"
                  ? t("address.addNewAddress")
                  : t("address.chooseFromAddresses")
              }
              primaryButtonName={t("address.saveAddress")}
              body={
                modalShow === "addEditAddress" ? (
                  <AddEditAddress
                    values={values}
                    setValues={setValues}
                    editingData={editingAddress}
                    handleSubmit={handleSubmit}
                    handleChange={handleChange}
                    setFieldValue={setFieldValue}
                    errors={errors}
                    touched={touched}
                    hideCheckbox
                  />
                ) : !!allAddresses.length ? (
                  <>
                    {allAddresses.map((d, i) => (
                      <AddressListItem
                        key={i}
                        onClick={() => {
                          setCheckoutAddress(d);
                          setEditingAddress(null);
                          setModalShow(false);
                        }}
                      >
                        <ListItem>{`${d.address} /`}</ListItem>
                        <ListItem>{`${d.phone} /`}</ListItem>
                        <ListItem>{d.firstName}</ListItem>
                      </AddressListItem>
                    ))}
                  </>
                ) : (
                  "Loading..."
                )
              }
              show={modalShow}
              onPrimaryButtonClick={handleSubmit}
              onHide={() => {
                setEditingAddress(null);
                setValues(addressInitialValues);
                setModalShow(false);
              }}
              hidePrimaryButton={modalShow !== "addEditAddress"}
              hideSecondaryButton
            />
            {!!allAddresses.length && !!checkoutAddress && (
              <Row xs={1} md={3} lg={6} key={checkoutAddress.id}>
                <Card style={{ minWidth: "100%", border: "none" }}>
                  <div>
                    <Card.Title className="d-flex mb-4 justify-content-between">
                      {`${checkoutAddress.firstName} ${checkoutAddress.lastName}`}
                      {data.isDefault && (
                        <Icon
                          iconName="fa fa-check-circle"
                          color={DARK_BACKGROUND_COLOR}
                        />
                      )}
                    </Card.Title>
                    {/* <Card.Subtitle className="mb-2 mt-3 text-muted">
                      {t("countries.armenia")}
                    </Card.Subtitle> */}
                    <Card.Subtitle className="mt-2 mb-2">
                      {t("address.phone")}: {checkoutAddress.phone}
                    </Card.Subtitle>
                    <Card.Text className="mt-0">
                      {t("address.title")}: {checkoutAddress.address}
                    </Card.Text>
                    <Flex justifyContent="space-between" height={10}>
                      <CardLink onClick={() => setModalShow(true)}>
                        {t("address.changeAddress")}
                      </CardLink>
                      <CardLink
                        color={PINK_RED_COLOR}
                        onClick={() => {
                          setEditingAddress(null);
                          setModalShow("addEditAddress");
                        }}
                      >
                        + {t("address.addAddress")}
                      </CardLink>
                    </Flex>
                  </div>
                </Card>
              </Row>
            )}
          </>
        )}
      </Formik>
    );
  }

  return (
    <Formik
      validationSchema={addAddressValidation}
      onSubmit={(values) => handleSubmitClick(values)}
      initialValues={addressInitialValues}
    >
      {({
        handleSubmit,
        handleChange,
        values,
        touched,
        errors,
        setValues,
        setFieldValue,
      }) => (
        <Paper>
          {loading ? (
            <Loading />
          ) : (
            <>
              <VerticallyCenteredModal
                title={t("address.addNewAddress")}
                primaryButtonName={t("address.saveAddress")}
                body={
                  <AddEditAddress
                    values={values}
                    setValues={setValues}
                    editingData={editingAddress}
                    handleSubmit={handleSubmit}
                    handleChange={handleChange}
                    setFieldValue={setFieldValue}
                    errors={errors}
                    touched={touched}
                    checkBoxLabel={t("address.setAsDefault")}
                  />
                }
                show={modalShow}
                onPrimaryButtonClick={handleSubmit}
                onHide={() => {
                  setEditingAddress(null);
                  setValues(addressInitialValues);
                  setModalShow(false);
                }}
                hideSecondaryButton
              />
              <DeleteModal
                show={addressForDelete}
                onPrimaryButtonClick={deleteAddressClick}
                onHide={() => setAddressForDelete(null)}
                text={t("address.deleteAddressText")}
              />
              <StyledContainer>
                <Heading5 style={{ color: GRAY_COLOR }}>
                  {t("profile.myAddresses")}
                </Heading5>
                <Divider color={`${GRAY_COLOR}50`} />
                {!allAddresses.length && !currentUser.email && (
                  <EmptyContent title={t("profile.noAddressText")} />
                )}

                {!allAddresses.length && currentUser.email && (
                  <EmptyContent
                    title={
                      <Flex
                        fontWeight="500"
                        onClick={() => setModalShow(true)}
                        justifyContent="center"
                        alignItems="center"
                        height="100%"
                        color={PINK_RED_COLOR}
                      >
                        +
                      </Flex>
                    }
                  />
                )}
                {!!allAddresses.length && (
                  <Container className="mt-2">
                    {allAddresses.map((data) => {
                      return (
                        <Row xs={1} md={3} lg={6} key={data.id}>
                          <Card style={{ minWidth: "100%" }}>
                            <Card.Body>
                              <Card.Title className="d-flex mb-3 justify-content-between">
                                {`${data.firstName} ${data.lastName}`}
                                {data.isDefault && (
                                  <Icon
                                    iconName="fa fa-check-circle"
                                    color={PINK_RED_COLOR}
                                  />
                                )}
                              </Card.Title>
                              <Card.Subtitle className="mb-2 text-muted">
                                {`${data.country || t("countries.armenia")}  ${
                                  data.zip
                                }`}
                              </Card.Subtitle>
                              <Card.Subtitle className="mt-2 mb-2">
                                {t("address.phone")}: {data.phone}
                              </Card.Subtitle>
                              <Card.Text className="mt-0">
                                {t("address.title")} : {data.address}
                              </Card.Text>
                              <CardLink
                                onClick={() => {
                                  if (!data.isDefault) {
                                    setDefaultClick(data.id);
                                  }
                                }}
                              >
                                {t("actions.setAsDefault")}
                              </CardLink>
                              <CardLink
                                onClick={() => {
                                  setEditingAddress(data);
                                  setModalShow(true);
                                }}
                              >
                                {t("actions.edit")}
                              </CardLink>
                              <CardLink
                                onClick={() => {
                                  setAddressForDelete(data.id);
                                }}
                              >
                                {t("actions.delete")}
                              </CardLink>
                            </Card.Body>
                          </Card>
                        </Row>
                      );
                    })}
                    <Flex
                      margin="20px 0 0 0"
                      fontWeight="500"
                      onClick={() => setModalShow(true)}
                      justifyContent="center"
                      alignItems="center"
                      height="100%"
                      color={PINK_RED_COLOR}
                    >
                      + {t("address.addAddress")}
                    </Flex>
                  </Container>
                )}
              </StyledContainer>
            </>
          )}
        </Paper>
      )}
    </Formik>
  );
};

export default AddressBook;
