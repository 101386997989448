import React from "react";
import styled from "styled-components";
import photo1 from "../../assets/imgs/gallery/4.jpg";
import photo2 from "../../assets/imgs/gallery/5.jpg";
import photo3 from "../../assets/imgs/gallery/6.jpg";
import photo4 from "../../assets/imgs/gallery/3.jpg";
import { SIDE_PADDING } from "../../constants/layout";

const ImageShowcase = styled.div`
  margin: 100px 0;
  padding-left: ${SIDE_PADDING};
  padding-right: ${SIDE_PADDING};
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 10px;

  @media (max-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
    margin-top: 16px;
    margin-bottom: 80px;
  }
`;

const Image = styled.img`
  width: 100%;
  height: auto;
  border-radius: 6px;
`;

const ImageGallery = () => {
  return (
    <ImageShowcase>
      <Image src={photo1} alt="Image 1" />
      <Image src={photo2} alt="Image 2" />
      <Image src={photo3} alt="Image 3" />
      <Image src={photo4} alt="Image 4" />
    </ImageShowcase>
  );
};

export default ImageGallery;
