import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useAuth } from "../../contexts/AuthContext";
import StyledLink from "../shared/links/Link.jsx";
import { CART_ROUTE, HOME_ROUTE, PROFILE_ROUTE } from "../../constants/routes";
import { Icon } from "../shared/icons/Icon.jsx";
import { MenuBars } from "../shared/icons/MenuBars.jsx";
import {
  NavbarContainer,
  IconsContainer,
  LogoContainer,
  LogoText,
  Nav,
  NavLink,
  Badge,
  NavDropdown,
  NavRightPart,
  ArmenianText,
} from "./styles";
import { scrollToTop } from "../../utils/common";
import { changeLanguage } from "i18next";
import { useTranslation } from "react-i18next";
import { LANGUAGES } from "../../constants/language";
import useMenuItems from "../../hooks/useMenu";
import { Navbar as NavbarBootstrap } from "react-bootstrap";

const Navbar = () => {
  const { i18n } = useTranslation();
  const language = i18n.language;
  const isArmenian = language === LANGUAGES.hy;

  const MENU_ITEMS_DATA = useMenuItems();

  const cart = useSelector(({ cart }) => cart);
  const { currentUser } = useAuth();
  const history = useHistory();
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const handleMenuClick = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <NavbarContainer fixed="top" variant={isMenuOpen ? "dark" : "light"}>
      <StyledLink
        to="/"
        onClick={() => {
          setIsMenuOpen(false);
          scrollToTop();
        }}
      >
        <LogoContainer>
          {/* <Logo src={logo} alt="logo" /> */}
          <LogoText>YourPhoto</LogoText>
        </LogoContainer>
      </StyledLink>
      <Nav $smobilemode={isMenuOpen}>
        {MENU_ITEMS_DATA.map((menuItem) => (
          <NavLink
            isArmenian={isArmenian}
            href={menuItem.url}
            $smobilemode={isMenuOpen}
            key={menuItem.id}
            onClick={() => {
              if (menuItem.route === "/photography") {
                history.push(menuItem.route);
              }
              setIsMenuOpen(false);
            }}
          >
            {menuItem.title}
          </NavLink>
        ))}
      </Nav>

      <NavRightPart>
        <NavbarBootstrap.Collapse id="basic-navbar-nav">
          <NavDropdown title={isArmenian ? "🇦🇲" : "🇬🇧"} id="language-selector">
            <NavDropdown.Item onClick={() => changeLanguage("en")}>
              <span role="img" aria-label="English Flag">
                🇬🇧
              </span>
              <span>&nbsp; English</span>
            </NavDropdown.Item>
            <NavDropdown.Item onClick={() => changeLanguage("hy")}>
              <span role="img" aria-label="Armenian Flag">
                🇦🇲
              </span>
              <ArmenianText>&nbsp; հայերեն</ArmenianText>
            </NavDropdown.Item>
          </NavDropdown>
        </NavbarBootstrap.Collapse>

        <IconsContainer>
          <Icon
            iconName="fa fa-home"
            aria-hidden="true"
            size="26px"
            hideOnSmallScreen
            onClick={() => {
              setIsMenuOpen(false);
              history.push(HOME_ROUTE);
              scrollToTop();
            }}
          />
          <Icon
            aria-hidden="true"
            onClick={() => {
              setIsMenuOpen(false);
              history.push(CART_ROUTE);
            }}
            iconName="fa fa-shopping-cart"
          />
          {cart.length > 0 && (
            <Badge bg="secondary" pill>
              {cart.length}
            </Badge>
          )}
          <Icon
            aria-hidden="true"
            onClick={() => {
              setIsMenuOpen(false);
              history.push(PROFILE_ROUTE);
            }}
            iconName={
              currentUser ? "fa fa-user-circle" : "fa fa-user-circle disabled"
            }
          />
          <MenuBars
            onClick={handleMenuClick}
            iconName={isMenuOpen ? "fa fa-times hide" : "fa fa-bars bars hide"}
            size={isMenuOpen ? "28px" : ""}
            aria-hidden="true"
          />
        </IconsContainer>
      </NavRightPart>
    </NavbarContainer>
  );
};

export default Navbar;
