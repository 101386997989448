import React from "react";
import styled from "styled-components";
import { DEVICES } from "../../constants/layout.js";
import Title from "./Title.jsx";
import { useTranslation } from "react-i18next";

const PricingContainer = styled.div`
  background-color: #ffffff;
  padding: 40px;
  padding-top: 80px;

  @media ${DEVICES.tablet} {
    padding: 20px;
    padding-top: 60px;
  }
`;

const PricingGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 40px;

  @media ${DEVICES.tablet} {
    gap: 20px;
  }
`;

const PricingCard = styled.div`
  background-color: #f5f3f5;
  border: 1px solid #d3cdd2;
  padding: 20px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
`;

const PricingTitle = styled.h3`
  margin: 0;
  font-size: 20px;
`;

const PricingPrice = styled.p`
  font-size: 24px;
  margin: 10px 0;
`;

const PricingDescription = styled.p`
  margin: 10px 0;
`;

const PricingSection = () => {
  const { t } = useTranslation();

  const pricingOptions = [
    {
      title: t("photographySection.basicPackage"),
      price: t("photographySection.basicPackagePrice"),
      description: t("photographySection.basicPackageDescription"),
    },
    {
      title: t("photographySection.eventsPackage"),
      price: t("photographySection.eventsPackagePrice"),
      description: t("photographySection.eventsPackageDescription"),
    },
    // Add more pricing options here
  ];

  return (
    <PricingContainer>
      <Title text={t("photographySection.pricing")} />
      <PricingGrid>
        {pricingOptions.map((option, index) => (
          <PricingCard key={index}>
            <PricingTitle>{option.title}</PricingTitle>
            <PricingPrice>{option.price}</PricingPrice>
            <PricingDescription>{option.description}</PricingDescription>
          </PricingCard>
        ))}
      </PricingGrid>
    </PricingContainer>
  );
};

export default PricingSection;
