import React from "react";
import styled from "styled-components";
import { DEVICES } from "../../constants/layout.js";
import serviceImage1 from "../../assets/imgs/imageSlider/1.webp";
import serviceImage2 from "../../assets/imgs/photography/portrait.webp";
import serviceImage3 from "../../assets/imgs/imageSlider/3.webp";
import Title from "./Title.jsx";
import { useTranslation } from "react-i18next";

const ServicesContainer = styled.div`
  background-color: #f5f3f5;
  padding: 40px;
  @media ${DEVICES.tablet} {
    padding: 20px;
  }
`;

const ServicesGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 40px;

  @media ${DEVICES.tablet} {
    gap: 20px;
  }
`;

const ServiceCard = styled.div`
  background-color: #ffffff;
  border: 1px solid #e0e0e0;
  padding: 20px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
`;

const ServiceImage = styled.img`
  width: 100px;
  height: 100px;
  object-fit: cover;
  border-radius: 50%;
  margin-bottom: 20px;
`;

const ServiceTitle = styled.h3`
  margin: 0;
  font-size: 20px;
`;

// const ServiceDescription = styled.p`
//   margin: 10px 0;
// `;

const Services = () => {
  const { t } = useTranslation();

  const services = [
    {
      title: t("photographySection.smallEvent"),
      description:
        "Capture the magic of your special day with elegance and emotion.",
      image: serviceImage1,
    },
    {
      title: t("photographySection.portraitPhotography"),
      description:
        "Elevate your portraits into artistic masterpieces with our expertise.",
      image: serviceImage2,
    },
    {
      title: t("photographySection.familyPhotography"),
      description:
        "Explore the beauty of nature through our captivating landscape shots.",
      image: serviceImage3,
    },
    // Add more services here
  ];

  return (
    <ServicesContainer>
      <Title text={t("photographySection.services")} />
      <ServicesGrid>
        {services.map((service, index) => (
          <ServiceCard key={index}>
            <ServiceImage src={service.image} alt={`Service ${index}`} />
            <ServiceTitle>{service.title}</ServiceTitle>
            {/* <ServiceDescription>{service.description}</ServiceDescription> */}
          </ServiceCard>
        ))}
      </ServicesGrid>
    </ServicesContainer>
  );
};

export default Services;
