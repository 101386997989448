import React from "react";
import styled from "styled-components";
import AppDownload from "./AppDownload";
import Footer from "./Footer";
import WelcomeSection from "./WelcomeSection";
import Button from "../shared/buttons/Button";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { ORDER_ROUTE } from "../../constants/routes";
import { useMediaQuery } from "react-responsive";
import { DEVICES } from "../../constants/layout";
import { LIGHT_GRADIENT_COLOR } from "../../constants/colors";
import { useTranslation } from "react-i18next";
import Products from "./Products";
import Ideas from "./Ideas";
import FeaturedText from "./FeaturedText";
import ImageGallery from "./ImageShowcase";
import FAQ from "./FAQ";
import Delivery from "./Delivery";

const FixedButtonContainer = styled.button`
  background: ${LIGHT_GRADIENT_COLOR};
  position: fixed;
  bottom: 0px;
  width: 100%;
  padding: 14px 14px;
  border: none;
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
  z-index: 1000;
`;

const HomePage = () => {
  const history = useHistory();
  const { t } = useTranslation();

  const isTabletOrMobile = useMediaQuery({ query: DEVICES.tablet });

  const onOrderCLick = () => {
    history.push(ORDER_ROUTE);
  };

  return (
    <div>
      <WelcomeSection onOrderCLick={onOrderCLick} />
      <Products />
      <FeaturedText />
      {/* <Advantages /> */}
      <Ideas />
      <AppDownload />
      <Delivery />
      {/* <TabFeatures /> */}
      {/* <KeyFeatures />
      <About />
      <Contact /> */}
      <FAQ />
      <ImageGallery />
      {/* <Accordion data={mockData} /> */}
      <Footer />
      {isTabletOrMobile && (
        <FixedButtonContainer>
          <Button
            height="48px"
            fullWidth
            variant="secondary"
            onClick={onOrderCLick}
          >
            {t("welcomeSection.orderButton")}
          </Button>
        </FixedButtonContainer>
      )}
    </div>
  );
};

export default HomePage;
