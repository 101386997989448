import React from "react";
import styled from "styled-components";
import { GRAY_COLOR } from "../../../constants/colors";

const StyledDivider = styled.div`
  background-color: ${GRAY_COLOR};
  width: 100%;
  height: ${({ thickness }) => thickness || "1px"};
  background-color: ${({ color }) => color || ""};
`;

export const Divider = ({ thickness, color, children, ...restProps }) => {
  return (
    <StyledDivider thickness={thickness} color={color} {...restProps}>
      {children}
    </StyledDivider>
  );
};
