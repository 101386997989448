import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import styled from "styled-components";
import { useAuth } from "../../contexts/AuthContext";
import Button from "../shared/buttons/Button";
import { DEVICES } from "../../constants/layout";
import { useParams } from "react-router-dom";
import { db } from "../../firebase"; // Assuming db is your Firestore instance
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { Icon } from "../shared/icons/Icon";
import { Heading4 } from "../shared/styledElements/Heading";
import { getPaymentInfo } from "../../utils/payment";
import Loading from "../Loading/Loading";
import { ORDER_STATUS } from "../../constants/payment";
import { useTranslation } from "react-i18next";

const Paper = styled.div`
  @media ${DEVICES.tablet} {
    width: 100%;
    padding: 2px;
  }
  border-radius: 12px;
  padding: 14px;
  padding-bottom: 80px;
`;

const BoxContainer = styled(Container)`
  height: 60vh;
  margin-bottom: 16px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
`;

const PaymentComplete = () => {
  const { t } = useTranslation();
  const { currentUser } = useAuth();
  const [paymentInfo, setPaymentInfo] = useState(null);
  const [statusCode, setStatusCode] = useState("0");
  const [loading, setLoading] = useState(false);
  const { id } = useParams(); // Get transactionId from URL

  const transactionId = id;

  const history = useHistory();

  useEffect(() => {
    (async () => {
      if (!transactionId) return;
      try {
        setLoading(true);
        const paymentsRef = db.collection("payments");
        const snapshot = await paymentsRef
          .where("transactionId", "==", String(transactionId))
          .get();

        if (!snapshot.empty) {
          const paymentData = snapshot.docs[0].data();
          setPaymentInfo(paymentData);

          const res = await getPaymentInfo(paymentData.paymentId);
          setStatusCode(res?.data?.paymentInfo?.OrderStatus || "0");
          if (
            res?.data?.paymentInfo &&
            res?.data?.paymentInfo?.OrderStatus !== paymentData.statusCode
          ) {
            const paymentDocRef = snapshot.docs[0].ref; // Get the document reference directly
            await paymentDocRef.update({
              status:
                ORDER_STATUS[res.data.paymentInfo.OrderStatus]?.title ||
                "unknown",
              statusCode: res.data.paymentInfo.OrderStatus,
              description: res.data.paymentInfo.Description,
              cardNumber: res.data.paymentInfo.CardNumber,
              refundedAmount: res.data.paymentInfo.RefundedAmount,
              approvedAmount: res.data.paymentInfo.ApprovedAmount,
              paymentState: res.data.paymentInfo.PaymentState,
            });
          }
        } else {
          console.log("No matching payment found.");
          setStatusCode("898");
        }
      } catch (error) {
        console.error("Error fetching payment info from Firestore:", error);
        setStatusCode("898");
      } finally {
        setLoading(false);
      }
    })();
  }, [transactionId]);

  return (
    <Paper>
      {loading ? (
        <BoxContainer>
          <Loading />
        </BoxContainer>
      ) : (
        <BoxContainer>
          <Icon
            size="120px"
            minSize="80px"
            color={ORDER_STATUS[statusCode].color}
            iconName={ORDER_STATUS[statusCode].iconName}
          />
          <Heading4 textAlign="center" className="my-12">
            {t(ORDER_STATUS[statusCode].textLabel)}
          </Heading4>
          {paymentInfo && (
            <>
              <p>Payment Number: #{paymentInfo.transactionId}</p>
              {statusCode !== "6" && (
                <p>Paid Amount: {paymentInfo?.amount} AMD</p>
              )}
            </>
          )}
          {!!(currentUser?.uid && statusCode === "2") ? (
            <Button onClick={() => history.push("/profile/order-history")}>
              {t("profile.orderHistory")}
            </Button>
          ) : (
            <Button onClick={() => history.push("/")}>
              {t("profile.returnHome")}
            </Button>
          )}
        </BoxContainer>
      )}
    </Paper>
  );
};

export default PaymentComplete;
