import React from "react";
import { Modal } from "react-bootstrap";
import Button from "../shared/buttons/Button.jsx";
import { useTranslation } from "react-i18next";

function ErrorModal(props) {
  const { t } = useTranslation();
  return (
    <>
      <Modal show={props.show} onHide={props.handleCloseError}>
        <Modal.Header closeButton>
          <Modal.Title>
            <h5>{t("error.somethingWentWrong")}</h5>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>{props.errorMessage}</Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={props.handleCloseError}>
            {t("actions.close")}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default ErrorModal;
