import React from "react";
import styled from "styled-components";
// import paymentImg from "../../assets/imgs/payments/pay3.png";
import {
  DARK_BACKGROUND_COLOR,
  WHITESMOKE_COLOR,
} from "../../constants/colors";
import { DEVICES, SIDE_PADDING } from "../../constants/layout";
import { FOOTER_ICONS } from "../../data/footerData";
import { Icon } from "../shared/icons/Icon";
import Anchor from "../shared/links/Anchor";
import { Divider } from "../shared/styledElements/Divider";
import { Flex } from "../shared/styledElements/Flex";
import { Span } from "../shared/styledElements/Span";
import { useTranslation } from "react-i18next";
import useFooterData from "../../hooks/useFooterData";

const StyledFooter = styled.footer`
  background-color: ${DARK_BACKGROUND_COLOR};
`;

const Container = styled.div`
  @media ${DEVICES.laptop} {
    flex-direction: column;
    padding: 24px 24px;
  }
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  color: ${WHITESMOKE_COLOR};
  padding: 58px ${SIDE_PADDING};
`;

const FooterSection = styled.div`
  @media ${DEVICES.laptopM} {
    width: 100%;
    margin-bottom: 30px;
    color: ${WHITESMOKE_COLOR};
  }
  @media ${DEVICES.laptop} {
    width: 100%;
    margin-bottom: 30px;
  }
  display: ${({ flex }) => (flex ? "flex" : "")};
  flex-direction: ${({ flexDirection }) => flexDirection || ""};
  height: 100%;
  width: ${({ width }) => width || "450px"};
  padding: ${({ padding }) => padding};
`;

const Heading2 = styled.h2`
  font-weight: 300;
  margin-bottom: 20px;
  color: ${WHITESMOKE_COLOR};
`;

const Heading4 = styled.h4`
  font-weight: 400;
  font-size: 20px;
  margin-bottom: 20px;
  color: ${WHITESMOKE_COLOR};
`;

const Navigation = styled.nav`
  @media ${DEVICES.laptopL} {
    margin-right: 16px;
  }
  width: 100%;
`;
const Contacts = styled.div`
  width: 100%;
`;

const UnorderedList = styled.ul`
  margin: 0;
  padding: 0;
  text-decoration: none;
  list-style-type: none;
  color: ${WHITESMOKE_COLOR};
`;

const ListItem = styled.li`
  color: ${WHITESMOKE_COLOR};
`;

const ContactItem = styled.li`
  display: flex;
  margin: 12px 0;
  align-items: center;
  color: ${WHITESMOKE_COLOR};
`;

const IconsContainer = styled.div`
  display: flex;
  margin: 24px 0;
`;

const Paragraph = styled.p`
  font-size: 16px;
  color: ${WHITESMOKE_COLOR};
`;

const Footer = () => {
  const { t } = useTranslation();
  const FOOTER_NAV_ITEMS = useFooterData();

  return (
    <StyledFooter id="contact">
      <Container className="main-footer">
        {/* About */}
        <FooterSection name="about" width="40%" padding="0 10px 0 0">
          <Heading2>YourPhoto</Heading2>
          <Paragraph>{t("about.shortText")}</Paragraph>
        </FooterSection>
        {/* Navigation & Contacts */}
        <FooterSection
          flex
          flexDirection="row"
          width="50%"
          name="navigation/contacts"
        >
          <Navigation>
            <Heading4>{t("footer.navigation")}</Heading4>
            <Navigation>
              <UnorderedList>
                {FOOTER_NAV_ITEMS.map((item, i) => (
                  <ListItem key={i}>
                    <Anchor
                      $showBorderOnHover
                      hoverColor={WHITESMOKE_COLOR}
                      color={WHITESMOKE_COLOR}
                      href={item.url}
                    >
                      {item.title}
                    </Anchor>
                  </ListItem>
                ))}
              </UnorderedList>
            </Navigation>
          </Navigation>
          <Contacts>
            <Heading4>{t("contacts.title")}</Heading4>
            <UnorderedList>
              <ContactItem>
                <Icon
                  color={WHITESMOKE_COLOR}
                  width="34px"
                  iconName="fa fa-whatsapp"
                />
                <Anchor
                  fontSize="14px"
                  color={WHITESMOKE_COLOR}
                  hoverColor={WHITESMOKE_COLOR}
                  href="tel:+37477540454"
                >
                  +374 77 54 04 54
                </Anchor>
              </ContactItem>
              <ContactItem>
                <Icon
                  color={WHITESMOKE_COLOR}
                  width="34px"
                  iconName="fa fa-envelope-o"
                />
                <Anchor
                  fontSize="13px"
                  hoverColor={WHITESMOKE_COLOR}
                  color={WHITESMOKE_COLOR}
                  href="mailto:yourphotoprints@gmail.com"
                >
                  yourphotoprints@gmail.com
                </Anchor>
              </ContactItem>
              <IconsContainer>
                {FOOTER_ICONS.map((item, i) => (
                  <Anchor
                    cursor="pointer"
                    key={i}
                    href={item.url}
                    target={item.target}
                  >
                    <Icon
                      size="34px"
                      cursor="pointer"
                      padding="0 24px 0 0"
                      color={WHITESMOKE_COLOR}
                      iconName={item.icon}
                    />
                  </Anchor>
                ))}
              </IconsContainer>
            </UnorderedList>
          </Contacts>
        </FooterSection>
        {/* Payments */}
        {/* <FooterSection width="30%" name="payments">
          <div className="footer-icon-payments">
            <Heading4>{t("footer.paymentMethods")}</Heading4>
            <img src={paymentImg} alt="payments" width="280px" />
          </div>
        </FooterSection> */}
      </Container>
      <Divider />
      <Flex
        color={WHITESMOKE_COLOR}
        padding="14px 0"
        justifyContent="center"
        alignItems="center"
      >
        <Span color={WHITESMOKE_COLOR} fontSize="14px">
          © Copyright {new Date().getUTCFullYear()} YourPhoto Online Print
          Studio
        </Span>
      </Flex>
    </StyledFooter>
  );
};

export default Footer;
