import passport from "../assets/imgs/photos/sizes/passport.webp";
import stadardImg from "../assets/imgs/photos/sizes/standard.webp";
import mediumImg from "../assets/imgs/photos/sizes/13x18.webp";
import instax from "../assets/imgs/photos/sizes/instax2.webp";
import squareImg from "../assets/imgs/photos/sizes/square.webp";
import polaroid from "../assets/imgs/photos/sizes/polaroid.webp";
// import collageImg from "../assets/imgs/photos/sizes/collage.webp";
import a3 from "../assets/imgs/photos/sizes/a3.webp";
import a4 from "../assets/imgs/photos/sizes/a4.webp";
import a5 from "../assets/imgs/photos/sizes/a5.webp";
import letter from "../assets/imgs/photos/sizes/letter.webp";

export const CLASSIC_PRINTS_DATA = [
  {
    id: 1,
    title: "Standard (10 x 15)",
    size: "10 x 15",
    price: 70,
    cName: "box3",
    img: stadardImg,
  },
  {
    id: 2,
    title: "Square (10 x 10)",
    size: "10 x 10",
    price: 120,
    cName: "box2",
    img: squareImg,
  },
  {
    id: 3,
    title: "Medium (13 x 18)",
    size: "13 x 18",
    price: 150,
    cName: "box4",
    img: mediumImg,
  },
  {
    id: 4,
    title: "Instax Mini (5.3 x 8.6)",
    size: "Instax Mini",
    price: 200,
    cName: "box4",
    img: instax,
  },
  {
    id: 5,
    title: "Polaroid (8.8 x 10.7)",
    size: "Polaroid",
    price: 250,
    cName: "box2",
    img: polaroid,
  },

  {
    id: 6,
    title: "A5 (14.8 x 21)",
    size: "A5",
    price: 350,
    cName: "box5",
    img: a5,
  },
  {
    id: 7,
    title: "Letter (28 x 21.5)",
    size: "21 x 28",
    price: 450,
    cName: "box6",
    img: letter,
  },
  {
    id: 8,
    title: "A4 (21 x 29.7)",
    size: "A4",
    price: 600,
    cName: "box7",
    img: a4,
  },
  {
    id: 9,
    title: "A3 (29.7 x 42)",
    size: "A3",
    price: 2900,
    cName: "box8",
    img: a3,
  },
  {
    id: 10,
    title: "Passport (3.5 x 4.5)",
    size: "3.5 x 4.5",
    price: 100,
    cName: "box1",
    minimum: 4,
    img: passport,
  },
];
