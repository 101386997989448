import moment from "moment";
import {
  DELIVERY_ADDITIONAL_PRICE,
  DELIVERY_PRICE,
  MINIMUM_ORDER_PRICE,
} from "../constants/prices";
import { CLASSIC_PRINTS_DATA } from "../data/classicPrintsData";

export const scrollToTop = () => window.scrollTo(0, 0);

export const checkBrowser = () => {
  const isFacebookBrowser = () => {
    const ua = navigator.userAgent || navigator.vendor || window.opera;
    return ua.indexOf("FBAN") > -1 || ua.indexOf("FBAV") > -1;
  };
  const isChrome = !!window.chrome;
  const isSafari =
    /constructor/i.test(window.HTMLElement) ||
    (function (p) {
      return p.toString() === "[object SafariRemoteNotification]";
    })(!window["safari"]);

  if ((!isChrome && !isSafari) || isFacebookBrowser) {
    window.location.replace("https://www.google.com/chrome/");
  }
};

export const parseCustomDateString = (dateString) => {
  if (typeof dateString !== "string") return null;
  const formats = [
    "DD.MM.YYYY, H:mm:ss",
    "M/D/YYYY, H:mm:ss A",
    "D/M/YYYY, H:mm:ss",
    "M/DD/YYYY, H:mm:ss A",
    "D.M.YYYY, H:mm:ss",
    "M.D.YYYY, H:mm:ss A",
  ];

  let parsedDate = null;

  for (const format of formats) {
    const parsedMoment = moment(dateString, format, true);
    if (parsedMoment.isValid()) {
      parsedDate = parsedMoment.toDate();
      break;
    }
  }

  return parsedDate;
};

export const swapDayAndMonthIfFuture = (date) => {
  const today = moment().startOf("day");
  const parsedDate = moment(date);

  if (parsedDate.isAfter(today)) {
    const swappedDate = parsedDate.format("D/M/YYYY");
    return swappedDate;
  }

  return parsedDate.format("D/M/YYYY");
};

export const formatNumber = (
  number,
  { maximumFractionDigits, minimumFractionDigits, locale = "en-US" } = {}
) => {
  const areMinAndMaxNotPassed =
    maximumFractionDigits === undefined && minimumFractionDigits === undefined;
  const min = areMinAndMaxNotPassed ? 2 : minimumFractionDigits;
  const max = areMinAndMaxNotPassed ? 2 : maximumFractionDigits;

  const formatConfig = {
    ...(min ? { minimumFractionDigits: min } : {}),
    ...(max ? { maximumFractionDigits: max } : {}),
  };

  return `${new Intl.NumberFormat(locale, formatConfig).format(+number || 0)}`;
};

export const getDateBeforeToday = (input) => {
  const currentDate = new Date();

  if (typeof input === "string") {
    const numericInput = parseInt(input);
    if (!isNaN(numericInput)) {
      const daysAgo = new Date(currentDate);
      daysAgo.setDate(currentDate.getDate() - numericInput);
      return daysAgo;
    }

    const lowerCaseInput = input.toLowerCase();
    if (lowerCaseInput === "day") {
      currentDate.setDate(currentDate.getDate() - 1);
    } else if (lowerCaseInput === "week") {
      currentDate.setDate(currentDate.getDate() - 7);
    } else if (lowerCaseInput === "month") {
      currentDate.setMonth(currentDate.getMonth() - 1);
    } else if (lowerCaseInput === "year") {
      currentDate.setFullYear(currentDate.getFullYear() - 1);
    }
    return currentDate;
  }

  return null; // Return null for invalid inputs
};

export const calculatePaperUsage = (inputArray) => {
  const paperUsage = {};

  inputArray.forEach((inputString) => {
    const parts = inputString.split(",").map((item) => item.trim());

    parts.forEach((part) => {
      const [countStr, sizeStr] = part.split("-").map((part) => part.trim());
      const count = parseInt(countStr);
      const size = sizeStr;

      const paperData = CLASSIC_PRINTS_DATA.find((data) => data.size === size);
      if (paperData) {
        if (!paperUsage[size]) {
          paperUsage[size] = 0;
        }
        paperUsage[size] += count;
      }
    });
  });

  return paperUsage;
};

export const getDeliveryPrice = (totalPrice) => {
  if (totalPrice < MINIMUM_ORDER_PRICE) return DELIVERY_ADDITIONAL_PRICE;
  return DELIVERY_PRICE;
};

// const inputString = "1 - 13 x 18, 2 - 13 x 18, 4 - 13 x 18, 6 - 13 x 18, 5 - 13 x 18, 7 - 13 x 18, 8 - 10 x 15, 30 - 13 x 18, 7 - 13 x 18, 2 - A3";

// bulk update any data
// useEffect(() => {
//   const updateMyOrders = async () => {
//     console.log(updated, "STARTED");
//     // if (true) return;

//     const orders = db.collection("orders");
//     console.log(orders, "orders");

//     const snapshot = await orders
//       .orderBy("order_date", "desc")
//       // .startAfter(300)
//       // .limit(300)
//       .get();
//     console.log(snapshot.docs.length, "im here 1");

//     const updatedOrders = snapshot.docs
//       .map((doc, i) => {
//         const data = doc.data();

//         const dateFormat = parseCustomDateString(data.order_date);
//         if (
//           !!dateFormat &&
//           data.order_date &&
//           typeof data.order_date === "string" &&
//           !isNaN(new Date(dateFormat))
//         ) {
//           console.log("UPDATING");
//           return {
//             id: doc.id,
//             data: { order_date: new Date(dateFormat) },
//           };
//         }
//         return null; // No need to update this document
//       })
//       .filter((d) => d !== null);

//     const b = await Promise.all(updatedOrders);
//     console.log(b, "im here 2");

//     const batch = db.batch();

//     console.log(updatedOrders, "map");

//     updatedOrders.map((updatedDoc) => {
//       const docRef = orders.doc(updatedDoc.id);
//       return batch.update(docRef, updatedDoc.data);
//     });

//     await Promise.all(updatedOrders);

//     await batch.commit();
//     console.log("------DONE------xx");

//     setUpdated(false);
//   };

//   updateMyOrders();
// }, [updated]);
