export const en = {
  welcomeSection: {
    title: "Turn your \n moments \n into lasting \n memories.",
    subtitle:
      "Preserve your precious moments with our high quality photo prints.",
    orderButton: "Order Now",
    smallText: "Receive your orders in 1-3 days!",
  },
  photographySection: {
    title: "We capture the perfect moments that will make memories.",
    subtitle:
      "Turning ordinary instants into extraordinary memories that endure.",
    services: "Services",
    smallEvent: "Small Event Photography",
    portraitPhotography: "Portrait Photography",
    familyPhotography: "Family Photography",
    capturedMoments: "Captured Moments",
    pricing: "Pricing",
    basicPackage: "Basic Package",
    basicPackagePrice: "from 15.000 AMD",
    basicPackageDescription: "Perfect for individuals for small photo shoots.",
    eventsPackage: "Event Package",
    eventsPackagePrice: "from 49.000 AMD",
    eventsPackageDescription: "Ideal for small events and special occasions.",
    contactUs: "Contact Us",
    name: "Name",
    phone: "Phone Number",
    email: "Email Address",
    message: "Your message here",
    send: "Send Message",
    successMessage: "Your message successfully sent",
  },
  menu: {
    prints: "Prints",
    photography: "Photography",
    ideas: "Ideas",
    app: "App",
    howToUse: "FAQ",
    delivery: "Delivery",
    aboutUs: "About us",
    contacts: "Contact us",
  },
  service: {
    exploreFeatures: "Choose your photo size",
  },

  ideas: {
    title: "Discover new ways to display your photos",
    spotlight: "Modern & Beautiful",
    spotlightDescription:
      "This cute display works beautifully with all of our Prints, but it looks even better when you opt for Retro Prints. All you’ll need is your chosen digital prints, a length of string, mini pegs, and, to add a little spotlight to your best moments, some string lights.",
    quickAndEasy: "Quick & Easy",
    quickAndEasyDescription:
      "Short on time but looking for a chic way to display your new prints? Here’s an idea: use a bulldog clip to create a unique photo holder. Simply stand the bulldog clip flat on a surface, pull the two metal arms shut so they’re touching, and pop your print between the arms. For a more luxurious look, try using gold or rose gold bulldog clips.",
    classic: "Can’t go wrong with a classic",
    classicDescription:
      "Whether you opt for the Framed Print or you choose to frame your prints at home, this is the easiest, and most classic, way of displaying your favourite snaps. Plus, to make your print really stand out, choose a frame that offsets the colours of the print so it really pops.",
  },
  application: {
    reviewInfo: "4.7 STARS AND OVER 5K ACTIVE USERS",
    printInfo: "Print photos from your \n computer or camera roll.",
    cta: "Watch tutorial",
  },
  keyFeatures: {
    title: "How to make your personalized order",
    choose: "Choose your product",
    chooseDescription:
      "Classic prints for every occasion. Custom prints for special ones. Decorative frames for emphasizing special moments. Stickers for journal decorations & more.",
    personalize: "Personalise it!",
    personalizeDescription:
      "Upload your photos easily to our website. Mobile uploads are also acceptable. Customize your orders with special tools. Make great gifts for your friends and family.",
    delivery: "Delivered to you direct",
    deliveryDescription:
      "With an instant order and fast delivery save your time and enjoy your orders. Now available for Armenian provinces as well.cc",
  },
  delivery: {
    title: "Delivery",
    hint: "Pay 600 AMD for delivery if your order amount exceeds 1500 AMD",
    preparation:
      "Once we receive your order, it takes us a few days to prepare it. Because every order is custom, it may take few days to process your order. Next, we send orders out for delivery. In Yerevan it may take 1-3 days for your order to be delivered. If your order amount is 1500 AMD or above, the delivery service costs 600 AMD.",
    process:
      "For the orders from Armenian provinces we use 'HayPost' services to deliver the order. It may take up to 2-7 working days after the processing to receive your order.",
    express:
      "*For faster delivery please talk to a representative in advance, before submitting your order. The cost for faster delivery may vary.",
  },
  about: {
    title: "About us",
    text: "At YourPhoto, Inc., our purpose is to help share life’s joy. We are founded in 2016 as the leading retailer and manufacturing platform for personalized products such as photo prints, photo books, stickers, etc. We are the first online photo printing services with mobile and web application, based in Armenia.",
    mission:
      " Our goal is to help consumers capture, preserve, and share life’s important moments through professional and personal photographs, and personalized products. The YourPhoto brand brings photos to life in photo books, gifts, home décor, and cards. We use high quality photo papers that qualifies European standards, and put all the effort to maintain the quality of the photos products.",
    subText:
      "YourPhoto helps you make the most out of life’s most memorable moments. As a personalized photos and services, we allow you to create; photo books; custom stationery, including cards, announcements, and invitations; as well as unique home decor and photo gifts. We help you connect with your family and friends by sharing your memories and photos in creative and innovative ways.",
    shortText:
      "Based in Yerevan, we've been printing your photos all across Armenia since 2016. Our pleasure is making your memories last forever. With top-notch quality and hassle-free online service, we're dedicated to ensuring each moment you cherish is preserved in vivid detail, delivering cherished memories right to your doorstep.",
  },
  contacts: {
    title: "Get in touch with us",
    address: "Address",
    phone: "Phone",
    facebook: "Facebook",
    instagram: "Instagram",
    whatsappViber: "Whatsapp/Viber",
    addressText: "6 Paronyan, Yerevan",
    email: "Email",
    subtitle: "Contacts",
    workingHours: "Working hours",
    workingHoursText: "Monday - Saturday",
  },
  footer: {
    home: "Home",
    navigation: "Navigation",
    paymentMethods: "Payment methods",
    reviews: "Reviews",
    liveChat: "Live chat",
  },
  printService: {
    uploadTitle: "Upload photos to start",
    uploadText:
      "Click to 'Add Photos' or simply drag and drop your photos here.",
    addPhotos: "Add Photos",
    addToCart: "Add to cart",
    cta: "Upload Photos",
    successfullyAddedToCart: "Selected photos are successfully added to cart",
    successfullyAddedToCartText:
      "You can edit or delete photos before purchasing.",
    goToCart: "Go to cart",
    photos: "photos",
    bottomText: "photos",
    classicPrints: "Classic Prints",
    addMorePhotos: "Add more photos",
    goToCheckout: "Go to checkout",
    cartItems: "Cart items",
    subtotal: "Subtotal",
    emptyCart: "Cart is empty",
    back: "Back",
    total: "Total",
    enterComment: "Enter your comment",
    placeOrder: "Place Order",
    placeOrderWithCard: "Confirm & Pay",
    allPhotosDeleted: "All photos has been deleted",
    paymentMethod: "Payment method",
    cashPayment: "Cash Payment",
    cardPayment: "Credit/Debit Card",
    idramPayment: "Pay With iDram",
  },
  address: {
    title: "Address",
    changeAddress: "Change Address",
    addAddress: "Add Address",
    firstName: "First Name",
    lastName: "Last Name",
    phone: "Phone Number",
    city: "City",
    state: "State",
    zipCode: "Zip Code",
    comment: "Comment",
    saveAddress: "Save Address",
    enterName: "enter your name",
    enterLastName: "enter your last name",
    enterCity: "enter your city",
    enterState: "enter your state",
    enterZipCode: "enter your zip code",
    enterComment: "enter your comment",
    enterPhone: "077 XX XX XX",
    addressExample: "6 Paronyan Street, Apartment 234, Entrance 7",
    enterAddress: "enter your address",
    addressSaved: "Address saved successfully",
    addNewAddress: "Add new address",
    chooseFromAddresses: "Choose from your addresses",
    deleteAddress: "Delete address",
    deleteAddressText: "Are you sure you want to delete this address?",
    setAsDefault: "Set as default address",
  },
  states: {
    yerevan: "Yerevan",
    ararat: "Ararat",
    aragatsotn: "Aragatsotn",
    armavir: "Armavir",
    gegharkunik: "Gegharkunik",
    kotayk: "Kotayk",
    lori: "Lori",
    shirak: "Shirak",
    syunik: "Syunik",
    tavush: "Tavush",
    vayotsDzor: "Vayots Dzor",
  },
  countries: {
    armenia: "Armenia",
  },
  authentication: {
    signIn: "Sign in",
    signUp: "Sign up",
    signUpTitle: "Create account",
    signOut: "Sign out",
    forgotPassword: "Forgot password?",
    login: "Log in",
    continueAsGuest: "Continue as a guest",
    dontHaveAccount: "Don't have an account?",
    email: "Email",
    phone: "Phone",
    enterEmail: "Enter your email",
    password: "Password",
    confirmPassword: "Confirm password",
    enterPassword: "Enter your password",
    enterConfirmPassword: "Confirm your password",
    enterPhoneNumber: "077 XX XX XX",
    resetYourPassword: "Reset your password",
    resetPassword: "Reset password",
    backTo: "Back to",
    firstName: "Name",
    lastName: "Last name",
    enterName: "Enter your name",
    enterLastName: "Enter your last name",
    alreadyHaveAccount: "Already have an account?",
  },
  profile: {
    user: "User",
    myProfile: "My Profile",
    myAddresses: "My Addresses",
    orderHistory: "Order History",
    returnHome: "Return to Homepage",
    recentOrders: "Recent Orders",
    noRecentOrders: "No recent orders",
    orderInfo: "Order info",
    orderDate: "Order date",
    orderNumber: "Order number",
    cityProvince: "City/Province",
    deliveryAddress: "Delivery address",
    price: "Price",
    orderPlaced: "Order placed",
    personalInformation: "Personal information",
    userName: "User name",
    birthday: "Birthday",
    loginInformation: "Login Information",
    userId: "User ID",
    emailAddress: "Email address",
    noAddressText: "Please sign in with your email to use address book",
  },
  actions: {
    delete: "Delete",
    edit: "Edit",
    update: "Update",
    confirm: "Confirm",
    cancel: "Cancel",
    save: "Save",
    setAsDefault: "Set as default",
    done: "Done",
    close: "Close",
    pause: "Pause",
    hide: "Hide",
  },
  order: {
    thankYou: "Thank You !",
    orderPlaced: "Your order has been placed.",
    uploadedSuccessfully: "Photos Are Uploaded Successfully",
    waitForUploadText: "Please wait a moment, photos are being uploaded...",
    uploading: "uploading",
    photosUploaded: "Photos have been uploaded",
    makePayment: "Please complete the payment to confirm your order.",
  },
  error: {
    somethingWentWrong: "Oops.. Something Went Wrong",
    somethingWentWrongText: "Please try again, or contact us",
    orderError:
      "Looks like something went wrong, for checking your order status please take a screenshot and call to 077540454",
    noInternetConnection: "No Internet Connection",
    failedToCreateAccount: "Failed to create an account",
    emptyCart: "Cart is empty, please add items to your cart and try again.",
    addingAddress: "Error adding address",
    cardPayment:
      "Card payment failed. Please try again or select the cash payment method.",
  },
  validation: {
    nameMinChar: "Names must have at least 2 characters",
    nameMaxChar: "Names can't be longer than 30 characters",
    nameRequired: "Name required",
    phoneMinChar: "Phone Numbers must have at least 9 characters",
    invalidPhone: "Phone number is not valid",
    phoneRequired: "Phone number required",
    addressRequired: "Address required",
    cityRequired: "City required",
  },
  data: {
    notSupportedFileFormat: "file format is not supported",
    currency: "AMD",
  },
  featuredText: {
    title: "Select - Order - Receive",
    description:
      "Selecting your cherished photos is simple with our user-friendly platform. Just upload your favorites from the comfort of your home. Once chosen, our streamlined process ensures seamless delivery, bringing your printed memories right to your doorstep across Armenia. It's that easy to transform moments into tangible treasures.",
  },
  faq: {
    title: "FAQ",
    fileFormat: {
      question: "What photo file formats do you accept?",
      answer:
        "We accept JPEG, PNG and other image file formats for printing your photos. Make sure your images are high-resolution for the best print quality. Additionally, when you order photos from our website, rest assured that we do not compress image quality; we ensure it remains at its best",
    },
    deliveryTime: {
      question: "How long will it take to receive my printed photos?",
      answer:
        "For delivery in Yerevan, orders typically arrive within 1-3 days. For regions within Armenia, delivery may take 2-7 working days. Delivery times may vary based on your location and chosen printing options",
    },
    orderModification: {
      question: "Can I cancel or modify my order after placing it?",
      answer:
        "You have the option to modify your order before confirming it. However, once an order is confirmed, it immediately enters our production process to ensure timely delivery. Unfortunately, we cannot cancel or modify orders after they have been confirmed.",
    },
    shippingCosts: {
      question: "How is the delivery pricing calculated?",
      answer:
        "Our delivery pricing remains consistent regardless of the shipping address, package weight, or regions within Armenia. If your order price exceeds 1500 AMD, the shipping fee is 600 AMD; otherwise, it's 1200 AMD. You can view the estimated shipping costs during the checkout process before finalizing your order.",
    },
    damagedPhotos: {
      question: "What if my printed photos arrive damaged?",
      answer:
        "In the rare event that your printed photos arrive damaged, please contact our customer support team immediately. We will arrange for a replacement or refund upon verification of the damage. Additionally, please note that we may require proof, such as photos, to process your claim.",
    },
  },
  payment: {
    pendingTitle: "Payment Pending",
    pendingText: "Your payment pending, please come back soon",
    successTitle: "Payment Success",
    successText: "Congratulations! Your order has been placed",
    declinedTitle: "Payment Declined",
    declinedText: "Oops! your payment has been declined",
    unAvailableTitle: "Payment Data not Available",
    unAvailableText: "Payment data not available",
  },
};
