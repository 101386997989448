import React from "react";
import BoxedContainer from "../shared/container/BoxedContainer";
import Accordion from "./../shared/accordion/Accordion";
import SectionTitle from "../shared/section/SectionTitle";
import useFaqData from "../../hooks/useFaqData.js";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";
import { DEVICES } from "../../constants/layout.js";

const FAQ = () => {
  const { t } = useTranslation();
  const faqData = useFaqData();
  const isTabletOrMobile = useMediaQuery({ query: DEVICES.tablet });

  return (
    <BoxedContainer
      padding="0 12%"
      marginLeft={isTabletOrMobile && "0px"}
      marginRight={isTabletOrMobile && "0px"}
      background="transparent"
      flexDirection="column"
      id="faq"
      flexDirectionTablet="column"
    >
      <SectionTitle title={t("faq.title")} />
      <Accordion data={faqData} />
    </BoxedContainer>
  );
};

export default FAQ;
