import React from "react";
import styled from "styled-components";
import { GRAY_COLOR } from "../../../constants/colors";

const StyledDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 300px;
  width: 100%;
  font-weight: 500;
  font-size: 18px;
  color: ${GRAY_COLOR};
`;

const EmptyContent = ({ title }) => {
  return <StyledDiv>{title}</StyledDiv>;
};

export default EmptyContent;
