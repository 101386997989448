import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import styled from "styled-components";
import {
  DARK_BACKGROUND_COLOR,
  GRAY_COLOR,
  WHITE_COLOR,
} from "../../../constants/colors";
import { DEVICES } from "../../../constants/layout";
import { Divider } from "../../shared/styledElements/Divider";
import { Heading5 } from "../../shared/styledElements/Heading";
import { useTranslation } from "react-i18next";
import { useRouteMatch } from "react-router-dom/cjs/react-router-dom.min";
import { db } from "../../../firebase";
import Loading from "../../Loading/Loading";

const Paper = styled.div`
  @media ${DEVICES.tablet} {
    width: 100%;
    padding: 2px;
  }
  background-color: ${WHITE_COLOR};
  height: fit-content;
  border-radius: 12px;
  box-shadow: 0px 1px 8px 1px ${DARK_BACKGROUND_COLOR}1A;
  padding: 14px;
`;

const StyledContainer = styled(Container)`
  padding-top: 20px;
  padding-bottom: 30px;
`;

const Title = styled.div`
  padding-top: 12px;
  font-weight: 500;
`;

const ViewUser = () => {
  const { t } = useTranslation();
  const { params } = useRouteMatch();
  const userId = params.id;
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState({});

  useEffect(() => {
    if (userId) {
      const getData = async () => {
        setLoading(true);
        const users = db.collection("users").doc(userId);
        const userData = await users.get();

        if (userData.data()) setUser(userData.data());

        setLoading(false);
      };
      getData();
    }
  }, [userId]);

  if (loading) {
    return (
      <Paper>
        <Loading />
      </Paper>
    );
  }
  return (
    <Paper>
      <StyledContainer>
        <Heading5 style={{ color: GRAY_COLOR }}>
          {t("profile.personalInformation")}
        </Heading5>
        <Divider color={`${GRAY_COLOR}50`} />
        <div>
          <Title margin="12px 0 0 0" fontWeight="500">
            {t("profile.user")}
          </Title>
          <div className="bg-light border">{user.name || "N/A"}</div>
          <Title padding="12px 0 0 0" fontWeight="500">
            {t("address.title")}
          </Title>
          <div className="bg-light border">{user.address || "N/A"}</div>
          <Title margin="12px 0 0 0" fontWeight="500">
            {t("address.phone")}
          </Title>
          <div className="bg-light border">{user.phoneNumber || "N/A"}</div>
          <Title margin="12px 0 0 0" fontWeight="500">
            {t("profile.birthday")}
          </Title>
          <div className="bg-light border">{user.birthday || "N/A"}</div>
        </div>
        <Heading5 style={{ color: GRAY_COLOR, paddingTop: 30 }}>
          {t("profile.loginInformation")}
        </Heading5>
        <Divider color={`${GRAY_COLOR}50`} />
        <div>
          <Title fontWeight="500">{t("profile.userId")}</Title>
          <div className="bg-light border">{(user.uid || "").slice(12)}</div>
          <Title fontWeight="500">{t("profile.emailAddress")}</Title>
          <div className="bg-light border">{user.email || "N/A"}</div>
        </div>
      </StyledContainer>
    </Paper>
  );
};

export default ViewUser;
