import React from "react";
import styled from "styled-components";
import {
  DARK_GRAY_COLOR,
  LIGHT_BACKGROUND_COLOR,
  LIGHT_TO_DARK_GRADIENT,
  WHITE_COLOR,
} from "../../../constants/colors";
import { useTranslation } from "react-i18next";

const Container = styled.div`
  padding: 8px;
  transition: transform 0.2s;
  &:hover {
    transform: scale(1.03);
  }
`;
const Box = styled.div`
  cursor: pointer;
  position: relative;
  /* height: 360px; */
  /* width: 300px; */
`;

const TextContainer = styled.div`
  position: absolute;
  background: ${LIGHT_TO_DARK_GRADIENT};
  width: 100%;
  bottom: 0;
  /* margin: 12px 8px; */
  padding: 8px;
  height: 30%;
  align-items: flex-end;
  display: flex;
  justify-content: space-between;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;

  /* margin: 12px 8px;
  font-weight: 600;
  font-size: 22px;
  color: ${DARK_GRAY_COLOR};
  user-select: none; */
`;

const Text = styled.span`
  font-weight: 500;
  font-size: 17px;
  color: ${WHITE_COLOR};
  user-select: none;
`;
const ImgContainer = styled.div`
  border-radius: 6px;
  overflow: hidden;
  background-color: ${LIGHT_BACKGROUND_COLOR};
  user-select: none;
`;
const Image = styled.img`
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
`;

const BoxedItem = ({ img, title, price, onChooseSizeClick }) => {
  const { t } = useTranslation();
  return (
    <Container onClick={onChooseSizeClick}>
      <Box>
        <ImgContainer>
          <Image src={img} alt="img" height="100%" width="100%" />
        </ImgContainer>
        <TextContainer>
          <Text>{title}</Text>
          <Text>
            {price} {t("data.currency")}
          </Text>
        </TextContainer>
      </Box>
    </Container>
  );
};

export default BoxedItem;
