import React from "react";
import styled from "styled-components";

const StyledDiv = styled.div`
  display: flex;
  font-size: ${({ fontSize }) => fontSize || ""};
  font-weight: ${({ fontWeight }) => fontWeight || ""};
  width: ${({ width }) => width || "100%"};
  color: ${({ color }) => color};
  height: ${({ height }) => height || "100%"};
  padding: ${({ padding }) => padding};
  margin: ${({ margin }) => margin};
  background-color: ${({ bgColor }) => bgColor};
  justify-content: ${({ justifyContent }) => justifyContent};
  flex-direction: ${({ flexDirection }) => flexDirection};
  align-items: ${({ alignItems }) => alignItems};
  cursor: ${({ onClick }) => (!!onClick ? "pointer" : "default")};
`;

export const Flex = ({ children, ...restProps }) => {
  return <StyledDiv {...restProps}>{children}</StyledDiv>;
};
