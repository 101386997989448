import React from "react";
import { Modal } from "react-bootstrap";
import { Link } from "react-router-dom";
import CartItem from "../Cart/CartItem";
import Button from "../shared/buttons/Button.jsx";
import { useTranslation } from "react-i18next";

function AddToCartModal(props) {
  const { t } = useTranslation();
  if (props.items.length === 0) {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <h6>{t("printService.allPhotosDeleted")}</h6>
        </Modal.Header>
        <Modal.Body>
          <h5>{t("printService.emptyCart")}</h5>
        </Modal.Body>
        <Modal.Footer>
          <Link to="/order">
            <Button onClick={props.onHide}> {t("printService.back")}</Button>
          </Link>
        </Modal.Footer>
      </Modal>
    );
  } else {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <div style={{ display: "flex", alignItems: "center" }}>
            <h6 style={{ margin: 0 }}>
              {t("printService.successfullyAddedToCart")}{" "}
            </h6>
            <i
              className="fa fa-check-circle"
              aria-hidden="true"
              style={{ color: "green", marginLeft: "3px" }}
            />
          </div>
        </Modal.Header>
        <Modal.Body>
          <p>{t("printService.successfullyAddedToCartText")}</p>
          <CartItem
            items={props.items[0]}
            deletecartitem={() => props.deleteCartItem(props.items[0].id)}
          />
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-between">
          <Button className="ml-2" onClick={props.onHide}>
            {t("printService.back")}
          </Button>

          <Link to="/cart">
            <Button width="140px" variant="secondary" onClick={props.goToCart}>
              {t("printService.goToCart")}
            </Button>
          </Link>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default AddToCartModal;
