import React from "react";
import appImg from "../../assets/imgs/app-download/pixelphone.webp";
import Button from "../shared/buttons/Button.jsx";
import styled from "styled-components";
import { DEVICES } from "../../constants/layout";
import Anchor from "../shared/links/Anchor";
import { useTranslation } from "react-i18next";
import { LANGUAGES } from "../../constants/language";
import BoxedContainer from "../shared/container/BoxedContainer.jsx";

const AppDownloadContainer = styled.figure`
  @media ${DEVICES.laptop} {
    flex-direction: column-reverse;
  }
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-evenly;
`;

const Caption = styled.figcaption`
  @media ${DEVICES.laptop} {
    padding: 0 48px;
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    width: 100%;
  }
  padding: 0 24px;
`;

const ImageContainer = styled.div`
  @media ${DEVICES.laptop} {
    padding: 0 40px;
  }
`;

const Image = styled.img`
  transition: transform 0.3s;
  &:hover {
    transform: scale(1.05);
  }
  @media ${DEVICES.laptop} {
    min-width: 400px;
    max-width: 600px;
  }
  @media ${DEVICES.laptop} {
    width: 100%;
  }
  @media ${DEVICES.laptopM} {
    min-width: 600px;
  }
  @media ${DEVICES.laptop} {
    width: 100%;
    min-width: auto;
  }
  min-width: 700px;
`;

const Heading1 = styled.h1`
  font-size: ${({ isArmenian }) => isArmenian && "28px"};
  font-weight: ${({ isArmenian }) => isArmenian && "500"};
  margin-bottom: ${({ isArmenian }) => isArmenian && "20px"};
  max-width: 460px;
  @media ${DEVICES.laptopM} {
    font-size: 24px;
  }
  padding-bottom: 14px;
`;

const Heading5 = styled.h5`
  font-size: ${({ isArmenian }) => isArmenian && "16px"};
  margin-bottom: ${({ isArmenian }) => isArmenian && "20px"};

  @media ${DEVICES.laptopM} {
    font-size: 12px;
  }
  @media ${DEVICES.laptop} {
    font-size: 10px;
  }
`;

const AppDownload = () => {
  const { t, i18n } = useTranslation();
  const isArmenian = i18n.language === LANGUAGES.hy;

  return (
    <BoxedContainer id="app-download" marginBottom="60px">
      <AppDownloadContainer>
        <Caption>
          <Heading5 isArmenian={isArmenian}>
            {t("application.reviewInfo")}
          </Heading5>
          <Heading1 isArmenian={isArmenian}>
            {t("application.printInfo")}
            {/* Print photos from your <br /> Instagram or camera roll. */}
          </Heading1>
          <Anchor
            href="https://www.youtube.com/watch?v=LL_KKwiQNlE"
            target="_blank"
          >
            <Button variant="outline-secondary">{t("application.cta")}</Button>
          </Anchor>
        </Caption>
        <ImageContainer>
          <Image width="500px" src={appImg} alt="" />
        </ImageContainer>
      </AppDownloadContainer>
    </BoxedContainer>
  );
};

export default AppDownload;
