import React from "react";
import styled from "styled-components";
import { DEVICES } from "../../constants/layout.js";
import artisticImage1 from "../../assets/imgs/imageSlider/10.webp";
import artisticImage2 from "../../assets/imgs/imageSlider/11.webp";
import artisticImage3 from "../../assets/imgs/imageSlider/12.webp";

const ArtisticShowcaseContainer = styled.div`
  background-color: #ffffff;
  padding: 40px;

  @media ${DEVICES.tablet} {
    padding: 20px;
  }
`;

const ArtisticShowcaseGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 20px;
`;

const ArtisticImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 8px;
`;

const ArtisticShowcaseSection = () => {
  const artisticImages = [artisticImage1, artisticImage2, artisticImage3];

  return (
    <ArtisticShowcaseContainer>
      <ArtisticShowcaseGrid>
        {artisticImages.map((image, index) => (
          <ArtisticImage
            key={index}
            src={image}
            alt={`Artistic Showcase ${index}`}
          />
        ))}
      </ArtisticShowcaseGrid>
    </ArtisticShowcaseContainer>
  );
};

export default ArtisticShowcaseSection;
