import React from "react";
import styled from "styled-components";
// import Carousel from "react-grid-carousel";
import { SIDE_PADDING } from "../../../constants/layout";
import { CLASSIC_PRINTS_DATA } from "../../../data/classicPrintsData";
import BoxedItem from "../../shared/boxedItem/BoxedItem";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const Root = styled.div`
  margin-bottom: 60px;
  padding-left: ${SIDE_PADDING};
  padding-right: ${SIDE_PADDING};
`;

const ClassicPrints = () => {
  const history = useHistory();
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 5,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 767, min: 0 },
      items: 1,
    },
  };

  const handleChooseSizeClick = (id) => history.push(`order/${id}`);

  return (
    <Root>
      <Carousel
        // infinite
        draggable
        responsive={responsive}
        swipeable
      >
        {CLASSIC_PRINTS_DATA.map(({ id, img, title, price }) => (
          <BoxedItem
            key={id}
            img={img}
            title={title}
            price={price}
            onChooseSizeClick={() => handleChooseSizeClick(id)}
          />
        ))}
      </Carousel>
    </Root>
  );
};

export default ClassicPrints;
