import React from "react";
import styled from "styled-components";
import { DEVICES } from "../../constants/layout";
import img from "../../assets/imgs/common/about.webp";
import logo from "../../assets/logo/logo-tiny.webp";
import { FOOTER_ICONS } from "../../data/footerData";
import { Icon } from "../shared/icons/Icon";
import { DARK_BACKGROUND_COLOR } from "../../constants/colors";
import Anchor from "../shared/links/Anchor";

const Container = styled.div`
  @media ${DEVICES.laptop} {
    flex-direction: column;
    padding: 0;
  }
  display: flex;
  padding: 24px 44px;
  height: 100vh;
  background-color: aliceblue;
`;

const Footer = styled.div``;

const Section = styled.div`
  @media ${DEVICES.laptop} {
    width: 100%;
  }
  padding: 20px;
  display: flex;
  height: 100%;
  width: 50%;
  flex-direction: column;
  font-weight: 300;
  font-size: 20px;
  justify-content: space-between;
`;
const TextContainer = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  font-weight: 300;
  font-size: 20px;
  margin-bottom: 40px;
`;

const ImgContainer = styled.div`
  display: flex;
  height: 300;
  width: 300;
`;

export const LogoText = styled.h1`
  justify-self: start;
  margin-bottom: 2px;
  margin-left: 8px;
  font-size: 26px;
  font-weight: 300;
  cursor: pointer;
  @media ${DEVICES.tablet} {
    margin-left: 4px;
    font-size: 20px;
  }
`;

const Logo = styled.img`
  height: 40px;
  width: 40px;
`;

export const LogoContainer = styled.div`
  display: flex;
  align-items: center;
  text-decoration: none !important;
  cursor: pointer;
  margin-bottom: 48px;
`;

export const Span = styled.span`
  @media ${DEVICES.laptop} {
    font-size: 16px;
  }
  margin-bottom: 28px;
  font-size: 24px;
  font-weight: 400;
`;
export const BoldText = styled.span`
  @media ${DEVICES.laptop} {
    font-size: 16px;
  }
  font-size: 24px;
  font-weight: 400;
`;

const IconsContainer = styled.div`
  display: flex;
  margin: 24px 0;
`;

const UnderConstruction = () => {
  return (
    <Container>
      <Section>
        <LogoContainer>
          <Logo src={logo} alt="logo" />
          <LogoText>YourPhoto</LogoText>
        </LogoContainer>
        <div>
          <TextContainer>
            <Span>
              Հարգելի հաճախորդ, կատարվում է կայքի վերապատրաստման աշխատանքներ:
            </Span>
            <BoldText>Կայքը կվերագործարկվի՝ 2022թ. Օգոստոսի 1-ից:</BoldText>
          </TextContainer>
        </div>
        <Footer>
          <IconsContainer>
            {FOOTER_ICONS.map((item, i) => (
              <Anchor
                cursor="pointer"
                key={i}
                href={item.url}
                target={item.target}
              >
                <Icon
                  size="30px"
                  cursor="pointer"
                  padding="0 10px 0 0"
                  color={DARK_BACKGROUND_COLOR}
                  iconName={item.icon}
                />
              </Anchor>
            ))}
          </IconsContainer>
        </Footer>
      </Section>
      <ImgContainer>
        <img src={img} alt="img" height="100%" width="100%" />
      </ImgContainer>
    </Container>
  );
};

export default UnderConstruction;
