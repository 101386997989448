import React, { useRef, useState } from "react";
import { Card, Form, Container, Alert } from "react-bootstrap";
import Button from "../shared/buttons/Button.jsx";
import { useDispatch } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { useAuth } from "../../contexts/AuthContext";
import { signIn } from "../../redux/actions";
import { useTranslation } from "react-i18next";

const SignIn = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const emailRef = useRef();
  const passwordRef = useRef();
  const { login, loginAsGuest } = useAuth();
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  async function handleSubmit(e) {
    e.preventDefault();
    try {
      setError("");
      setLoading(true);
      await login(emailRef.current.value, passwordRef.current.value);
      history.push("/");
      dispatch(signIn());

      // toast.success("Successfully logged in");
    } catch {
      setError("Failed to sign in");
    }
    setLoading(false);
  }

  const guestSignIn = async () => {
    await loginAsGuest();
    history.push("/");
  };

  return (
    <>
      <Container
        className="d-flex align-items-center justify-content-center"
        style={{ minHeight: "86vh" }}
      >
        <div className="w-100" style={{ maxWidth: "400px" }}>
          <Card>
            <Card.Body>
              <h2 className="text-center mb-4">{t("authentication.signIn")}</h2>
              {error && <Alert variant="danger">{error}</Alert>}
              <Form onSubmit={handleSubmit}>
                <Form.Group id="email" className="mb-3">
                  <Form.Label>{t("authentication.email")}</Form.Label>
                  <Form.Control
                    placeholder={t("authentication.enterEmail")}
                    type="email"
                    ref={emailRef}
                    required
                  />
                </Form.Group>
                <Form.Group id="password">
                  <Form.Label>{t("authentication.password")}</Form.Label>
                  <Form.Control
                    placeholder={t("authentication.enterPassword")}
                    type="password"
                    ref={passwordRef}
                    requierd="true"
                  />
                </Form.Group>
                <div className="w-100 text-center mt-3">
                  <Link style={{ color: "grey" }} to="/forgot-password">
                    {t("authentication.forgotPassword")}
                  </Link>
                </div>
                <Button
                  className="w-100 mt-3"
                  type="submit"
                  variant="secondary"
                  disabled={loading}
                >
                  {t("authentication.login")}
                </Button>
                <Button
                  className="w-100 mt-3 mb-2"
                  onClick={guestSignIn}
                  variant="primary"
                  disabled={loading}
                >
                  {t("authentication.continueAsGuest")}
                </Button>
              </Form>
            </Card.Body>
          </Card>
          <div className="w-100 text-center mt-2">
            {t("authentication.dontHaveAccount")}{" "}
            <Link style={{ color: "blue" }} to="/signup">
              {" "}
              {t("authentication.signUp")}
            </Link>
          </div>
        </div>
      </Container>
    </>
  );
};

export default SignIn;
