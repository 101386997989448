import React, { useEffect } from "react";
import { BrowserRouter as Router, Switch } from "react-router-dom";
import Orders from "./Orders/Orders";
import PrivateRoute from "../Authentication/PrivateRoute";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import usePermissions from "../../hooks/usePermissions";
import ViewUser from "./Users/ViewUser";

const Dashboard = () => {
  const history = useHistory();
  const { isAdmin } = usePermissions();

  useEffect(() => {
    if (!isAdmin) history.push("/");
  }, [history, isAdmin]);

  return (
    <Router>
      <Switch>
        {!!isAdmin && (
          <>
            <PrivateRoute
              exact
              path="/dashboard/user/:id"
              component={ViewUser}
            />
            <PrivateRoute exact path="/dashboard/user" component={ViewUser} />
            <PrivateRoute exact path="/dashboard" component={Orders} />
          </>
        )}
      </Switch>
    </Router>
  );
};

export default Dashboard;
