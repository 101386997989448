import React from "react";
import styled from "styled-components";

const StyledSpan = styled.span`
  cursor: ${({ clickable }) => (clickable ? "pointer" : "default")};
  font-size: ${({ fontSize }) => fontSize || ""};
  font-weight: ${({ fontWeight }) => fontWeight || ""};
  color: ${({ color }) => color};
  padding: ${({ padding }) => padding};
  margin: ${({ margin }) => margin};
`;

export const Span = ({
  fontWeight,
  color,
  fontSize,
  children,
  margin,
  padding,
  clickable,
  ...restProps
}) => {
  return (
    <StyledSpan
      clickable={clickable}
      margin={margin}
      padding={padding}
      color={color}
      fontWeight={fontWeight}
      fontSize={fontSize}
      {...restProps}
    >
      {children}
    </StyledSpan>
  );
};
