import React, { useEffect } from "react";
import CartItem from "./CartItem";
import CartTotal from "./CartTotal";
import "./Cart.css";
import { Container } from "react-bootstrap";
import emptyCartImg from "./../../assets/icons/empty-cart.png";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  updateCart,
  updateItemsSum,
  updateSubTotal,
} from "../../redux/actions";
import { GRAY_COLOR } from "../../constants/colors";
import { useTranslation } from "react-i18next";
import Button from "../shared/buttons/Button";
import { ORDER_ROUTE } from "../../constants/routes";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const Cart = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const cart = useSelector((state) => state.cart);
  const itemsSum = useSelector((state) => state.itemsSum);
  const subtotal = useSelector((state) => state.subtotal);

  const deleteCartItem = (id) => {
    const filteredCart = cart.filter((cartItem) => {
      return cartItem.id !== id;
    });
    dispatch(updateCart(filteredCart));
  };

  useEffect(() => {
    const checkSum = () => {
      let sum = 0;
      let obj = { ...cart };
      for (let key in obj) {
        sum += obj[key].price;
      }

      dispatch(updateItemsSum(sum));
      dispatch(updateSubTotal(sum));
    };
    checkSum();
  }, [cart, dispatch]);

  if (cart.length > 0) {
    return (
      <div className="cart-container">
        <div className="cart-items">
          {cart.map((el, i) => {
            return (
              <CartItem
                key={i}
                items={el}
                deletecartitem={() => deleteCartItem(el.id)}
              />
            );
          })}
          <Link to="/order">
            <div className="cart-items-add">
              + {t("printService.addMorePhotos")}
            </div>
          </Link>
        </div>
        <div className="cart-total">
          <CartTotal subtotal={subtotal} itemsSum={itemsSum} cart={cart} />
        </div>
      </div>
    );
  } else {
    return (
      <div className="cart-container">
        <Container className="w-100 mt-5 d-flex flex-column align-items-center justify-content-center">
          <h5 style={{ color: GRAY_COLOR }}>{t("printService.emptyCart")}</h5>
          <img alt="cart is empty" src={emptyCartImg} />
          <Button
            variant="secondary"
            onClick={() => {
              history.push(`${ORDER_ROUTE}/4`);
            }}
          >
            {t("welcomeSection.orderButton")}
          </Button>
        </Container>
      </div>
    );
  }
};

export default Cart;
