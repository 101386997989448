import React from "react";
import VerticallyCenteredModal from "./VerticallyCenteredModal";
import { Flex } from "../../shared/styledElements/Flex";
import { useTranslation } from "react-i18next";

const DeleteModal = ({ text, ...restProps }) => {
  const { t } = useTranslation();
  return (
    <VerticallyCenteredModal
      size="md"
      title={t("address.deleteAddress")}
      hideSecondaryButton
      primaryButtonName={t("actions.delete")}
      body={
        <Flex height="80px" alignItems="center" fontSize="18px">
          {text}
        </Flex>
      }
      {...restProps}
    />
  );
};

export default DeleteModal;
