import React from "react";
import styled from "styled-components";
import { GRAY_COLOR } from "../../../constants/colors";
import { DEVICES } from "../../../constants/layout";

const StyledIcon = styled.i`
  @media ${DEVICES.tablet} {
    font-size: ${({ minSize }) => minSize || "24px"};
    align-self: center;
    display: ${({ hideOnSmallScreen }) => hideOnSmallScreen && "none"};
  }
  width: ${({ width }) => width || ""};
  margin: ${({ margin }) => margin || ""};
  padding: ${({ padding }) => padding || ""};
  font-size: ${({ size }) => size || "24px"};
  color: ${({ iconName, color }) =>
    iconName?.includes("disabled") ? GRAY_COLOR : color || ""};
  cursor: ${({ onClick, cursor }) =>
    onClick ? "pointer" : cursor || "default"};
`;

export const Icon = ({
  onClick,
  iconName,
  size,
  hideOnSmallScreen,
  minSize,
  width,
  padding,
  margin,
  color,
  cursor,
  ...restProps
}) => {
  return (
    <StyledIcon
      {...(onClick ? { onClick: () => onClick() } : undefined)}
      color={color}
      className={iconName}
      iconName={iconName}
      aria-hidden="true"
      size={size}
      width={width}
      cursor={cursor}
      margin={margin}
      padding={padding}
      minSize={minSize}
      hideOnSmallScreen={hideOnSmallScreen}
      {...restProps}
    />
  );
};
