import React from "react";
import { Modal } from "react-bootstrap";
import CloseButton from "react-bootstrap/CloseButton";
import Button from "../buttons/Button";
import { Heading5 } from "../styledElements/Heading";

const VerticallyCenteredModal = ({
  onHide = () => {},
  show,
  title,
  body,
  primaryButtonName,
  secondaryButtonName,
  hideSecondaryButton,
  hidePrimaryButton,
  onPrimaryButtonClick = () => {},
  ...restProps
}) => {
  return (
    <Modal
      show={show}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      {...restProps}
    >
      <Modal.Header>
        <Modal.Title as={Heading5} id="contained-modal-title-vcenter">
          {title}
        </Modal.Title>
        <CloseButton onClick={onHide} />
      </Modal.Header>
      <Modal.Body>{body}</Modal.Body>
      {hidePrimaryButton && hideSecondaryButton ? null : (
        <Modal.Footer
          className={
            hideSecondaryButton ? "" : "d-flex justify-content-between"
          }
        >
          {!hideSecondaryButton && (
            <Button variant="primary" onClick={onHide}>
              {secondaryButtonName || "Cancel"}
            </Button>
          )}
          {!hidePrimaryButton && (
            <Button onClick={onPrimaryButtonClick} variant="secondary">
              {primaryButtonName || "Save Changes"}
            </Button>
          )}
        </Modal.Footer>
      )}
    </Modal>
  );
};

export default VerticallyCenteredModal;
