import { useTranslation } from "react-i18next";

const useFooterData = () => {
  const { t } = useTranslation();

  const FOOTER_NAV_ITEMS = [
    {
      title: t("footer.home"),
      url: "/#",
    },
    {
      title: t("menu.prints"),
      url: "#prints",
    },
    {
      title: t("footer.reviews"),
      url: "https://www.facebook.com/yourphotoprinting/reviews",
      target: "_blank",
    },
    {
      title: t("menu.aboutUs"),
      url: "#about",
    },
    {
      title: t("footer.liveChat"),
      url: "https://tawk.to/chat/5c59946a6cb1ff3c14cb321a/1eu1ga0pk",
      target: "_blank",
    },
  ];
  return FOOTER_NAV_ITEMS;
};

export default useFooterData;
