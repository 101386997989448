import React from "react";
import { DEVICES } from "../../../constants/layout";
import styled from "styled-components";
import Button from "../../shared/buttons/Button";
import { useAuth } from "../../../contexts/AuthContext";
import ListData from "./ListData";
import { initPayment } from "../../../utils/payment";

const Paper = styled.div`
  @media ${DEVICES.tablet} {
    width: 100%;
  }
  padding: 10px;
  padding-bottom: 40px;
`;

const ToolBarContainer = styled.div`
  background-color: #f8f7f7;
  display: flex;
  justify-content: flex-end;
  padding: 0 18px;
`;

const PaymentList = () => {
  const { currentUser } = useAuth();

  return (
    <Paper>
      <ToolBarContainer>
        <Button
          onClick={() => initPayment({ user: currentUser })}
          className="my-4"
          variant="secondary"
        >
          Test Pay
        </Button>
      </ToolBarContainer>
      <ListData />
    </Paper>
  );
};

export default PaymentList;
