import React, { useState, useEffect } from "react";
import {
  Accordion as BSAccordion,
  Col,
  Container,
  Row as BSRow,
} from "react-bootstrap";
import styled from "styled-components";
import {
  DARK_BACKGROUND_COLOR,
  GRAY_COLOR,
  GREEN_COLOR,
  WHITE_COLOR,
} from "../../constants/colors";
import { useAuth } from "../../contexts/AuthContext";
import { db } from "../../firebase";
import EmptyContent from "../shared/emptyContent/EmptyContent";
import Status from "../shared/status/Status";
import { Divider } from "../shared/styledElements/Divider";
import { Heading5 } from "../shared/styledElements/Heading";
import { Span } from "../shared/styledElements/Span";
import Loading from "../Loading/Loading";
import { DEVICES } from "../../constants/layout";
import { useTranslation } from "react-i18next";

const Paper = styled.div`
  @media ${DEVICES.tablet} {
    width: 100%;
    padding: 2px;
  }
  display: flex;
  align-items: center;
  background-color: ${WHITE_COLOR};
  min-height: 400px;
  height: fit-content;
  width: 68%;
  border-radius: 12px;
  box-shadow: 0px 1px 8px 1px ${DARK_BACKGROUND_COLOR}1A;
  padding: 14px;
`;

const StyledContainer = styled(Container)`
  padding-top: 20px;
  padding-bottom: 30px;
`;

const Accordion = styled(BSAccordion)`
  margin-top: 10px;
`;

const Row = styled(BSRow)`
  @media ${DEVICES.tablet} {
    font-size: 14px;
  }
  margin-bottom: 10px;
  font-weight: 400;
`;

const OrderHistory = () => {
  const { t } = useTranslation();
  const { currentUser } = useAuth();
  const [loading, setLoading] = useState(false);
  const [myOrders, setMyOrders] = useState([]);

  useEffect(
    () => {
      const ordersFromDB = [];
      const getData = async () => {
        setLoading(true);
        const orders = db.collection("orders");
        const res = await orders
          .where("user_uid", "==", currentUser.uid)
          .orderBy("order_date", "desc")
          .limit(20)
          .get();

        res.forEach((doc) => {
          ordersFromDB.push({ ...doc.data(), key: doc.id });
        });

        setMyOrders(ordersFromDB);
        setLoading(false);
        return () => myOrders();
      };

      return getData();
    },
    // eslint-disable-next-line
    [currentUser]
  );

  return (
    <Paper>
      {loading ? (
        <Loading />
      ) : (
        <StyledContainer>
          <Heading5 style={{ color: GRAY_COLOR }}>
            {t("profile.recentOrders")}
          </Heading5>
          <Divider color={`${GRAY_COLOR}50`} />
          {!myOrders.length && (
            <EmptyContent title={t("profile.noRecentOrders")} />
          )}
          {!!myOrders.length &&
            myOrders.map((order, i) => (
              <Accordion defaultActiveKey={0} key={i}>
                <Accordion.Item eventKey={i}>
                  <Accordion.Header>
                    <div
                      style={{
                        width: "100%",
                        fontSize: "15px",
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      {order.order_date && (
                        <Span padding="0px 20px 0px 0px">
                          {t("profile.orderDate")}:{" "}
                          {typeof order.order_date === "string"
                            ? order.order_date
                            : order.order_date.toDate().toLocaleDateString()}
                        </Span>
                      )}
                      <Status
                        fontWeight="500"
                        color={GREEN_COLOR}
                        margin="0 12px 0 0"
                        title="Order Placed"
                      />
                    </div>
                  </Accordion.Header>
                  <Accordion.Body>
                    <Container>
                      <Row>
                        <Col sm={6}>
                          {t("profile.orderNumber")}:{" "}
                          <Span fontWeight="500">{order.order_number}</Span>
                        </Col>
                        <Col>
                          {t("profile.price")}:{" "}
                          <Span fontWeight="500">
                            {order.order_price} {t("data.currency")}
                          </Span>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          {t("profile.orderInfo")}:{" "}
                          <Span fontWeight="500">{order.order_info}</Span>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          {t("address.phone")}:{" "}
                          <Span fontWeight="500">{order.user_phone}</Span>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          {t("profile.cityProvince")}:{" "}
                          <Span fontWeight="500">
                            {order.user_state}, {order.user_city}
                          </Span>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          {t("profile.deliveryAddress")}:{" "}
                          <Span fontWeight="500">{order.user_address}</Span>
                        </Col>
                      </Row>
                      {order.user_comment && (
                        <Row>
                          <Col>
                            Comment:{" "}
                            <Span fontWeight="500">{order.user_comment}</Span>
                          </Col>
                        </Row>
                      )}
                    </Container>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            ))}
        </StyledContainer>
      )}
    </Paper>
  );
};

export default OrderHistory;
