import React from "react";
import { useMemo } from "react";
import { Container } from "react-bootstrap";
import styled from "styled-components";
import {
  DARK_BACKGROUND_COLOR,
  GRAY_COLOR,
  WHITE_COLOR,
} from "../../constants/colors";
import { DEVICES } from "../../constants/layout";
import { useAuth } from "../../contexts/AuthContext";
import { Divider } from "../shared/styledElements/Divider";
import { Heading5 } from "../shared/styledElements/Heading";

const Paper = styled.div`
  @media ${DEVICES.tablet} {
    width: 100%;
    padding: 2px;
  }
  background-color: ${WHITE_COLOR};
  height: fit-content;
  width: 68%;
  border-radius: 12px;
  box-shadow: 0px 1px 8px 1px ${DARK_BACKGROUND_COLOR}1A;
  padding: 14px;
`;

const StyledContainer = styled(Container)`
  padding-top: 20px;
  padding-bottom: 30px;
`;

const Title = styled.div`
  padding-top: 12px;
  font-weight: 500;
`;

const Settings = () => {
  const { currentUser } = useAuth();

  const user = useMemo(() => currentUser || {}, [currentUser]);

  return (
    <Paper>
      <StyledContainer>
        <Heading5 style={{ color: GRAY_COLOR }}>Account Settings</Heading5>
        <Divider color={`${GRAY_COLOR}50`} />
        <div>
          <Title margin="12px 0 0 0" fontWeight="500">
            Name
          </Title>
          <div className="bg-light border">{user.displayName || "Guest"}</div>
          <Title padding="12px 0 0 0" fontWeight="500">
            Address
          </Title>
          <div className="bg-light border">Fuchik 7/5</div>
          <Title margin="12px 0 0 0" fontWeight="500">
            Phone Number
          </Title>
          <div className="bg-light border">{user.phoneNumber || "N/A"}</div>
          <Title margin="12px 0 0 0" fontWeight="500">
            Birthday
          </Title>
          <div className="bg-light border">{user.birthday || "N/A"}</div>
        </div>
        <Heading5 style={{ color: GRAY_COLOR, paddingTop: 30 }}>
          Login Information
        </Heading5>
        <Divider color={`${GRAY_COLOR}50`} />
        <div>
          <Title fontWeight="500">User Id</Title>
          <div className="bg-light border">{user.uid}</div>
          <Title fontWeight="500">Email Address</Title>
          <div className="bg-light border">{user.email}</div>
        </div>
      </StyledContainer>
    </Paper>
  );
};

export default Settings;
