import styled, { css } from "styled-components";
import {
  Badge as BootstrapBadge,
  Nav as BootstrapNav,
  Navbar as BootstrapNavbar,
} from "react-bootstrap";
import {
  DARK_GRAY_COLOR,
  PINK_RED_COLOR,
  WHITESMOKE_COLOR,
  WHITE_COLOR,
} from "../../constants/colors";
import { DEVICES, SIDE_PADDING } from "../../constants/layout";
import { NavDropdown as NavDropdownBootstrap } from "react-bootstrap";

export const NavbarContainer = styled(BootstrapNavbar)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  z-index: 800;
  width: 100%;
  height: 60px;
  background-color: ${WHITE_COLOR};
  padding-left: ${SIDE_PADDING};
  padding-right: ${SIDE_PADDING};
  /* box-shadow: 1px 0.1px 0.1px rgba(0, 0, 0, 0.1); */
`;

export const LogoText = styled.h1`
  justify-self: start;
  margin-bottom: 2px;
  font-size: 26px;
  font-weight: 300;
  cursor: pointer;
  @media ${DEVICES.tablet} {
    margin-left: 4px;
    font-size: 20px;
  }
`;
export const ArmenianText = styled.span`
  font-size: 15px;
`;

export const LogoContainer = styled.div`
  display: flex;
  align-items: center;
  text-decoration: none !important;
  cursor: pointer;
  @media ${DEVICES.tablet} {
    margin-left: 10px;
  }
`;

export const Logo = styled.img`
  width: 34px;
  height: 34px;
  cursor: pointer;
  @media ${DEVICES.tablet} {
    width: 30px;
    height: 30px;
  }
`;

export const Nav = styled(BootstrapNav)`
  ${({ $smobilemode }) =>
    $smobilemode
      ? css`
          display: flex;
          flex-direction: column !important;
          position: absolute;
          height: 70vh;
          top: 60px;
          left: 0px;
          width: 100%;
          font-size: 18px;
          border-bottom: 1px solid ${PINK_RED_COLOR};
        `
      : css`
          font-size: 18px;
          transition: 350ms;
          @media ${DEVICES.laptop} {
            display: none;
          }
        `};
`;

export const NavLink = styled(Nav.Link)`
  margin: 0 4.4px;
  font-size: ${({ isArmenian }) => (isArmenian ? "14px" : "17px")};
  ${({ $smobilemode }) =>
    $smobilemode &&
    css`
      margin: 0;
      display: flex;

      align-items: center;
      transition: 350ms;
      justify-content: center;
      width: 100%;
      height: 100%;
      color: ${WHITESMOKE_COLOR};
      font-size: 18px;
      background-color: ${DARK_GRAY_COLOR};
      &:hover {
        background-color: ${PINK_RED_COLOR};
      }
    `};
`;

export const NavRightPart = styled.div`
  display: flex;
  align-items: center;
`;

export const NavDropdown = styled(NavDropdownBootstrap)`
  .dropdown-toggle {
    font-size: 26px;

    margin-top: 7px;
    @media ${DEVICES.tablet} {
      margin-top: 4px;
      font-size: 22px;
    }
  }
`;

export const IconsContainer = styled.div`
  @media ${DEVICES.laptop} {
    margin-right: 0px;
  }
  width: 150px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  position: relative;
`;

export const Badge = styled(BootstrapBadge)`
  position: absolute;
  text-align: center;
  right: 52px;
  bottom: 16px;
  /* top: 8px; */
  background-color: ${PINK_RED_COLOR};
  color: ${WHITESMOKE_COLOR};
  @media ${DEVICES.laptop} {
    right: 70px;
    bottom: 16px;
  }
  @media ${DEVICES.tablet} {
    right: 102px;
    bottom: 16px;
  }
`;
