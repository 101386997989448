import React from "react";
import styled from "styled-components";
import { PINK_RED_COLOR } from "../../../constants/colors";

const defaultBorderStyle = `2px solid ${PINK_RED_COLOR}`;

const StyledAnchor = styled.a`
  &:hover {
    color: ${({ hoverColor }) => hoverColor || "default"};
    border-bottom: ${({ $showBorderOnHover }) =>
      $showBorderOnHover ? defaultBorderStyle : "none"};
    text-decoration: ${({ $showBorderOnHover }) =>
      $showBorderOnHover ? "none" : "default"};
  }
  font-size: ${({ fontSize }) => fontSize};
  text-decoration: ${({ $underlined }) =>
    $underlined ? "default" : "none !important"};
  cursor: ${({ cursor }) => cursor || "pointer"};
  color: ${({ color }) => color || "default"};
`;

const Anchor = ({
  href,
  $showBorderOnHover = false,
  $underlined = false,
  color,
  hoverColor,
  cursor,
  fontSize,
  onClick = () => {},
  children,
  ...restProps
}) => {
  return (
    <StyledAnchor
      href={href}
      color={color}
      cursor={cursor}
      hoverColor={hoverColor}
      onClick={onClick}
      fontSize={fontSize}
      $underlined={$underlined}
      $showBorderOnHover={$showBorderOnHover}
      {...restProps}
    >
      {children}
    </StyledAnchor>
  );
};

export default Anchor;
