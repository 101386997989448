import { CLASSIC_PRINTS_DATA } from "../../data/classicPrintsData";

// const initialState = [
//   CLASSIC_PRINTS_DATA[3].size,
//   CLASSIC_PRINTS_DATA[3].price,
//   "Glossy",
// ];
const initialState = CLASSIC_PRINTS_DATA[2];

const selectedSizeReducer = (state = initialState, action) => {
  const selectedSize = action.payload;

  switch (action.type) {
    case "UPDATE_SELECTED_SIZE": {
      return selectedSize;
    }
    default:
      return state;
  }
};

export default selectedSizeReducer;
