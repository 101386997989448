import React, { useRef, useState } from "react";
import styled from "styled-components";
import { DEVICES } from "../../constants/layout.js";
import Title from "./Title.jsx";
import Button from "../shared/buttons/Button.jsx";
import { useMediaQuery } from "react-responsive";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import axios from "axios";

const ContactContainer = styled.div`
  background-color: #ffffff;
  padding: 40px;

  @media ${DEVICES.tablet} {
    padding: 20px;
  }
`;

const ContactForm = styled.form`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const ContactInput = styled.input`
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
`;

const ContactTextArea = styled.textarea`
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
`;

const ContactSection = () => {
  const { t } = useTranslation();
  const isTabletOrMobile = useMediaQuery({ query: DEVICES.tablet });

  // Create refs for the input fields
  const nameInputRef = useRef();
  const phoneInputRef = useRef();
  const emailInputRef = useRef();
  const messageInputRef = useRef();

  // State to track whether the form is valid
  // eslint-disable-next-line
  const [isFormValid, setIsFormValid] = useState(false);

  // Function to handle input changes
  const handleInputChange = () => {
    // Check if the "Name" and "Message" fields have values
    const nameValue = nameInputRef.current.value;
    const messageValue = messageInputRef.current.value;
    setIsFormValid(!!nameValue && !!messageValue);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Access the input values using the refs
    const name = nameInputRef.current.value;
    const phone = phoneInputRef.current.value;
    const email = emailInputRef.current.value;
    const message = messageInputRef.current.value;

    const webhookUrl =
      process.env.REACT_APP_SLACK_APP_PHOTO_SERVICE_WEBHOOK_URL;
    const info = `
:date: *Date*: ${new Date().toISOString()}          
:raising_hand: *Name*: ${name}
:iPhone: *Phone*: ${phone}
:e-mail: *Email*: ${email}
:memo: *Email*: ${message}`;

    const payload = `{"text":"${info}"}`;
    const data = payload;
    try {
      await axios.post(webhookUrl, data);
      toast.success(t("photographySection.successMessage"));
    } catch {
      toast.error(t("error.somethingWentWrong"), {
        position: "top-right",
        autoClose: 30000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  return (
    <ContactContainer>
      <Title text={t("photographySection.contactUs")} />
      <ContactForm onSubmit={handleSubmit}>
        <ContactInput
          type="text"
          placeholder={t("photographySection.name")}
          ref={nameInputRef} // Attach the ref to the input
          required // Make the "Name" field required
          onChange={handleInputChange} // Handle input changes
        />
        <ContactInput
          type="phone"
          placeholder={t("photographySection.phone")}
          ref={phoneInputRef} // Attach the ref to the input
          required // Make the "phone" field required
        />
        <ContactInput
          type="email"
          placeholder={t("photographySection.email")}
          ref={emailInputRef} // Attach the ref to the input
          required // Make the "email" field required
        />
        <ContactTextArea
          placeholder={t("photographySection.message")}
          rows="4"
          ref={messageInputRef} // Attach the ref to the textarea
          required // Make the "Message" field required
          onChange={handleInputChange} // Handle input changes
        />
        <Button
          className={isTabletOrMobile ? "w-100 mt-4" : "mt-4"}
          type="submit"
          variant="secondary"
        >
          {t("photographySection.send")}
        </Button>
      </ContactForm>
    </ContactContainer>
  );
};

export default ContactSection;
