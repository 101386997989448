import React from "react";
import styled from "styled-components";

const StyledStatus = styled.div`
  font-size: ${({ fontSize }) => fontSize || ""};
  font-weight: ${({ fontWeight }) => fontWeight || ""};
  color: ${({ color }) => color};
  padding: ${({ padding }) => padding};
  margin: ${({ margin }) => margin};
`;

const Status = ({ title, margin, padding, color, ...restProps }) => {
  return (
    <StyledStatus
      margin={margin}
      padding={padding}
      color={color}
      {...restProps}
    >
      {title}
    </StyledStatus>
  );
};

export default Status;
