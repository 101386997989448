import React from "react";
import Button from "../shared/buttons/Button.jsx";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { getDeliveryPrice } from "../../utils/common.js";
import styled from "styled-components";
import { GRAY_COLOR } from "../../constants/colors.js";
import { DELIVERY_ADDITIONAL_PRICE } from "../../constants/prices.js";

const Hint = styled.span`
  margin-top: 18px;
  margin-bottom: 10px;

  font-size: small;
  color: ${GRAY_COLOR};
`;

const CartTotal = ({ cart, itemsSum, subtotal }) => {
  const { t } = useTranslation();

  return (
    <div className="cart-total-items">
      <Link to="cart/checkout" className="cart-total-button">
        <Button variant="secondary">{t("printService.goToCheckout")}</Button>
      </Link>
      <div className="cart-total-details">
        <div>
          <span>
            {t("printService.cartItems")} ({cart.length})
          </span>
          <span>
            {itemsSum} {t("data.currency")}
          </span>
        </div>
        <div>
          <span>{t("menu.delivery")}</span>
          <span>
            {getDeliveryPrice(itemsSum)} {t("data.currency")}
          </span>
        </div>
        {getDeliveryPrice(itemsSum) >= DELIVERY_ADDITIONAL_PRICE && (
          <div>
            <Hint>{t("delivery.hint")}</Hint>
          </div>
        )}
      </div>
      <div className="cart-total-subtotal">
        <h4>{t("printService.total")}</h4>
        <h4>
          {subtotal} {t("data.currency")}
        </h4>
      </div>
    </div>
  );
};

export default CartTotal;
