import { useTranslation } from "react-i18next";

const useArmenianStatesData = () => {
  const { t } = useTranslation();

  return [
    t("states.yerevan"),
    t("states.ararat"),
    t("states.aragatsotn"),
    t("states.armavir"),
    t("states.gegharkunik"),
    t("states.kotayk"),
    t("states.lori"),
    t("states.shirak"),
    t("states.syunik"),
    t("states.tavush"),
    t("states.vayotsDzor"),
  ];
};

export default useArmenianStatesData;
