import { useTranslation } from "react-i18next";

const useFaqData = () => {
  const { t } = useTranslation();

  return [
    {
      id: 1,
      title: t("faq.fileFormat.question"),
      content: t("faq.fileFormat.answer"),
    },
    {
      id: 2,
      title: t("faq.deliveryTime.question"),
      content: t("faq.deliveryTime.answer"),
    },
    {
      id: 3,
      title: t("faq.shippingCosts.question"),
      content: t("faq.shippingCosts.answer"),
    },
    {
      id: 4,
      title: t("faq.orderModification.question"),
      content: t("faq.orderModification.answer"),
    },
    {
      id: 5,
      title: t("faq.damagedPhotos.question"),
      content: t("faq.damagedPhotos.answer"),
    },
  ];
};

export default useFaqData;
