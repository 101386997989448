import React from "react";
import styled from "styled-components";
import {
  DARK_BACKGROUND_COLOR,
  LIGHT_BACKGROUND_COLOR,
  WHITE_COLOR,
} from "../../../constants/colors";
import SectionTitle from "./SectionTitle";
import { SIDE_PADDING } from "../../../constants/layout";

const StyledSection = styled.section`
  // This boxed prop is adding boxed layout
  margin-left: ${({ boxed }) => boxed && SIDE_PADDING};
  margin-right: ${({ boxed }) => boxed && SIDE_PADDING};
  background-color: ${({ boxed }) => boxed && LIGHT_BACKGROUND_COLOR};
  border-radius: ${({ boxed }) => boxed && "14px"};
  // This boxed prop is adding boxed layout

  background-color: ${({ theme }) => {
    if (theme === "light") return LIGHT_BACKGROUND_COLOR;
    if (theme === "dark") return DARK_BACKGROUND_COLOR;
    return WHITE_COLOR;
  }};
  display: ${({ flex }) => (flex ? "flex" : "")};
  justify-content: ${({ flex }) => (flex ? "center" : "")};
  align-items: ${({ flex }) => (flex ? "center" : "")};

  padding-bottom: ${({ paddingBottom }) => paddingBottom && "48px"};
`;

const Section = ({ children, title, flex, theme, boxed, ...restProps }) => {
  return (
    <StyledSection boxed={boxed} flex={flex} theme={theme} {...restProps}>
      <SectionTitle theme={theme} title={title} />
      {children}
    </StyledSection>
  );
};

export default Section;
