import React from "react";
import Button from "../shared/buttons/Button.jsx";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { LANGUAGES } from "../../constants/language.js";
import { getDeliveryPrice } from "../../utils/common.js";

const CheckoutTotal = (props) => {
  const { t, i18n } = useTranslation();
  const isArmenian = i18n.language === LANGUAGES.hy;

  const cart = useSelector((state) => state.cart);
  const itemsSum = useSelector((state) => state.itemsSum);
  const subtotal = useSelector((state) => state.subtotal);

  return (
    <div className="checkout-total-items">
      <div className="checkout-total-details">
        <div>
          <span>
            {t("printService.cartItems")} ({cart.length})
          </span>
          <span>
            {itemsSum} {t("data.currency")}
          </span>
        </div>
        <div>
          <span>{t("menu.delivery")} </span>
          <span>
            {getDeliveryPrice(itemsSum)} {t("data.currency")}
          </span>
        </div>
      </div>
      <div className="checkout-total-subtotal">
        {isArmenian ? (
          <h5>{t("printService.total")}</h5>
        ) : (
          <h4>{t("printService.total")}</h4>
        )}
        <h4>
          {subtotal} {t("data.currency")}
        </h4>
      </div>
      <Link
        onClick={props.handleSubmitClick}
        to="/cart/checkout"
        className="checkout-total-button"
      >
        <Button
          className="submit-button"
          onClick={props.submitOrder}
          variant="secondary"
          type="submit"
        >
          {t(
            props.cardPayment
              ? "printService.placeOrderWithCard"
              : "printService.placeOrder"
          )}
        </Button>
      </Link>
    </div>
  );
};

export default CheckoutTotal;
