import React, { useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Form,
  DropdownButton,
  ButtonGroup,
  Dropdown,
} from "react-bootstrap";
import { toast } from "react-toastify";

import styled from "styled-components";
import {
  DARK_BACKGROUND_COLOR,
  GRAY_COLOR,
  WHITE_COLOR,
} from "../../../constants/colors";
import { useAuth } from "../../../contexts/AuthContext";
import { db } from "../../../firebase";
import EmptyContent from "../../shared/emptyContent/EmptyContent";
import { Heading5 } from "../../shared/styledElements/Heading";
import { Span } from "../../shared/styledElements/Span";
import Loading from "../../Loading/Loading";
import { DEVICES } from "../../../constants/layout";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useFormik } from "formik";
import Button from "../../shared/buttons/Button";
import { calculatePaperUsage, getDateBeforeToday } from "../../../utils/common";
import TotalBar from "./TotalBar";
import DeleteModal from "../../shared/modals/DeleteModal";

const Paper = styled.div`
  @media ${DEVICES.tablet} {
    width: 100%;
    padding: 2px;
  }
  min-width: 640px;
  border-radius: 12px;
  padding: 14px;
  padding-bottom: 80px;
`;

const DataContainer = styled.div`
  @media ${DEVICES.tablet} {
  }
`;

const OrderItem = styled.div`
  background-color: ${WHITE_COLOR};
  border-radius: 12px;
  box-shadow: 0px 1px 8px 1px ${DARK_BACKGROUND_COLOR}1A;
  padding: 14px;
  margin-bottom: 20px;
`;

const fields = [
  { id: 1, title: "Name", value: "user_name" },
  { id: 2, title: "Last Name", value: "user_lastname" },
  { id: 3, title: "Date", value: "order_date" },
  { id: 4, title: "Order", value: "order_number" },
  { id: 5, title: "Price", value: "order_price" },
  { id: 6, title: "User ID", value: "user_uid" },
  { id: 7, title: "Phone", value: "user_phone" },
  { id: 8, title: "Address", value: "user_address" },
  { id: 9, title: "Order Info", value: "order_info" },
];

const rowTitles = [
  "Name",
  "Phone",
  "Address",
  "Date",
  "Info",
  "Price",
  "Action",
];

const matchData = {
  Exact: "==",
  All: ">=",
};
const orderBy = {
  Ascending: "asc",
  Descending: "desc",
};
const dataCount = {
  10: 10,
  20: 20,
  50: 50,
  100: 100,
  200: 200,
  400: 400,
  600: 600,
  1000: 1000,
};

const Orders = () => {
  const { t } = useTranslation();
  const { currentUser } = useAuth();
  const [loading, setLoading] = useState(false);
  const [myOrders, setMyOrders] = useState([]);
  const history = useHistory();
  const [searchValue, setSearchValue] = useState("");
  const [totalValue, setTotalValue] = useState("");
  const [orderForDelete, setOrderForDelete] = useState(null);
  const [refetch, setRefetch] = useState(false);
  const [paperUsageInfo, setPaperUsageInfo] = useState(null);

  const initialValues = {
    name: "",
    searchText: "",
    searchTitle: "Name",
    match: "All",
    order: "Ascending",
    count: 20,
  };

  const formik = useFormik({ initialValues });

  const { values, handleChange, setValues, setFieldValue } = formik;
  const { searchText, searchTitle, match, order, count } = values;

  const onSearchClick = () => {
    setSearchValue(searchText);
  };

  const onResetClick = () => {
    setSearchValue("");
    setValues(initialValues);
    setFieldValue(searchText, "");
  };

  useEffect(() => {
    const getData = async () => {
      const users = db.collection("users");
      let res;
      res = await users.where("email", "==", "haynur001@gmail.com").get();
      console.log(res, "res");

      const a = res.docs.map((doc) => {
        const data = doc.data();
        console.log(data, "DATA");
        return data;
      });
      return a;
    };

    try {
      getData();
    } catch (e) {
      console.log(e);
    }
  }, []);

  useEffect(() => {
    const getData = async () => {
      setLoading(true);
      const orders = db.collection("orders");
      let res;
      const selectedField = fields.find((f) => {
        return f.title === searchTitle;
      }).value;

      if (searchValue && selectedField) {
        let updatedSearchValue = searchValue;

        if (selectedField === "order_date") {
          updatedSearchValue = getDateBeforeToday(searchValue);
        }

        res = await orders
          .where(selectedField, matchData[match], updatedSearchValue)
          .orderBy(selectedField, orderBy[order])
          .limit(dataCount[count])
          .get();
      } else {
        res = await orders
          // .where("order_date", ">=", new Date("08/18/2023"))
          .orderBy("order_date", "desc")
          .limit(dataCount[count])
          .get();
      }

      if (res.size === 0) {
        // No documents found that match the query criteria
        setLoading(false);
        setMyOrders([]);

        return;
      }
      let total = 0;
      let paperUsageData = [];

      const ordersFromDB = res.docs.map((doc) => {
        const data = doc.data();
        total += data.order_price || 0;
        if (data.order_info) paperUsageData.push(data.order_info);

        return {
          ...data,
          key: doc.id,
        };
      });

      setPaperUsageInfo(calculatePaperUsage(paperUsageData));
      setTotalValue(total);
      setMyOrders(ordersFromDB);
      setLoading(false);
      paperUsageData = [];
    };

    try {
      getData();
    } catch (e) {
      console.log(e);
    }
  }, [count, currentUser, match, order, searchTitle, searchValue, refetch]);

  const deleteByDocId = async (docId) => {
    try {
      const ordersRef = db.collection("orders");
      const docRef = ordersRef.doc(docId);

      const docSnapshot = await docRef.get();
      if (!docSnapshot.exists) {
        toast.error(`Document with ID ${docId} does not exist.`);
        return;
      }
      await docRef.delete();
      toast.success(`Document with ID ${docId} successfully deleted.`);
    } catch (error) {
      toast.error("Error deleting document:", error);
    }
    setOrderForDelete(null);
    setRefetch(!refetch);
  };

  if (loading) {
    return (
      <Paper>
        <Loading />
      </Paper>
    );
  }

  return (
    <Paper>
      <Container>
        <Heading5 color={GRAY_COLOR}>Orders</Heading5>
        <Form.Group className="mb-4 mt-1" controlId="formGridState">
          <Row>
            <Col xs={3} sm={3} md={3} lg={3}>
              <Form.Select
                as="select"
                name="searchTitle"
                value={values.searchTitle}
                onChange={handleChange}
              >
                {fields.map((field) => (
                  <option key={field.id}>{field.title}</option>
                ))}
              </Form.Select>
            </Col>
            <Col xs={3} sm={3} md={3} lg={3}>
              <Form.Select
                as="select"
                name="count"
                value={values.count}
                onChange={handleChange}
              >
                {Object.values(dataCount).map((value, i) => (
                  <option key={value}>{value}</option>
                ))}
              </Form.Select>
            </Col>
            <Col xs={3} sm={3} md={3} lg={3}>
              <Form.Select
                as="select"
                name="match"
                value={values.match}
                onChange={handleChange}
              >
                {Object.keys(matchData).map((value, i) => (
                  <option key={value}>{value}</option>
                ))}
              </Form.Select>
            </Col>
            <Col xs={3} sm={3} md={3} lg={3}>
              <Form.Select
                as="select"
                name="order"
                value={values.order}
                onChange={handleChange}
              >
                {Object.keys(orderBy).map((value, i) => (
                  <option key={value}>{value}</option>
                ))}
              </Form.Select>
            </Col>
          </Row>
        </Form.Group>
        <Form.Group className="mt-4 mb-4" controlId="searchBox">
          <Row>
            <Col xs={9} sm={9} md={9} lg={9}>
              <Form.Control
                type="text"
                name="searchText"
                placeholder="Start typing to search..."
                value={values.searchText}
                onChange={handleChange}
              />
            </Col>
            <Col xs={3} sm={3} md={3} lg={3}>
              <Button fullWidth onClick={onResetClick}>
                Reset
              </Button>
            </Col>
          </Row>
          <Row className="mt-4 mb-4">
            <Col xs={4} sm={4} md={4} lg={4}>
              <Button variant="secondary" fullWidth onClick={onSearchClick}>
                Search
              </Button>
            </Col>
          </Row>
        </Form.Group>

        {!myOrders.length && (
          <EmptyContent title={t("profile.noRecentOrders")} />
        )}

        <DataContainer>
          {!!myOrders.length && (
            <Row>
              {rowTitles.map((title) => (
                <Col key={title}>
                  <strong>{title}</strong>
                  &nbsp;
                </Col>
              ))}
            </Row>
          )}
          {!!myOrders.length &&
            myOrders.map((order, i) => {
              const {
                key,
                user_name,
                user_lastname,
                order_info,
                order_price,
                user_uid,
                user_phone,
                user_address,
              } = order;
              return (
                <OrderItem key={i}>
                  <Row>
                    <Col>
                      <Span
                        clickable
                        onClick={() => {
                          if (user_uid) {
                            history.push(`/dashboard/user/${user_uid}`);
                          }
                        }}
                      >
                        <strong>
                          {user_name || "-"} {user_lastname}
                        </strong>
                      </Span>
                    </Col>
                    <Col>{user_phone || "-"}</Col>
                    <Col>{user_address || "-"}</Col>
                    <Col>
                      <Span>
                        {typeof order.order_date === "string" ||
                        !order.order_date
                          ? order.order_date || "-"
                          : order.order_date?.toDate()?.toLocaleDateString()}
                      </Span>
                    </Col>
                    <Col>{order_info || "-"}</Col>
                    <Col>{order_price || "-"}</Col>
                    <Col>
                      <DropdownButton
                        variant="light"
                        as={ButtonGroup}
                        align="start"
                        title=""
                        id="dropdown-menu-align-responsive-1"
                      >
                        <Dropdown.Item>Edit</Dropdown.Item>
                        <Dropdown.Item
                          onClick={() =>
                            setOrderForDelete({
                              event: "delete",
                              id: key,
                              title: `${user_name} ${user_lastname || ""}`,
                            })
                          }
                        >
                          Delete
                        </Dropdown.Item>
                      </DropdownButton>
                    </Col>
                  </Row>
                </OrderItem>
              );
            })}
        </DataContainer>
      </Container>
      <TotalBar
        count={myOrders.length}
        price={totalValue}
        paperUsageInfo={paperUsageInfo}
      />
      {!!orderForDelete && (
        <DeleteModal
          show
          title="Delete order"
          onPrimaryButtonClick={() => deleteByDocId(orderForDelete?.id)}
          onHide={() => setOrderForDelete(null)}
          text={`Are you sure you want to delete order - ${orderForDelete?.title} `}
        />
      )}
    </Paper>
  );
};

export default Orders;
