import React from "react";
import ControlledTabs from "../shared/tabs/Tabs";
import ClassicPrints from "./ClassicPrints/ClassicPrints";
import "./Products.css";

const Products = () => {
  return (
    <>
      <div className="products">
        <ControlledTabs
          data={[
            { id: 1, name: "Classic Prints", component: <ClassicPrints /> },
            { id: 2, name: "Special Prints", component: <ClassicPrints /> },
            { id: 3, name: "Custom Prints", component: <ClassicPrints /> },
            { id: 4, name: "Accessories", component: <ClassicPrints /> },
            { id: 5, name: "Gifts", component: <ClassicPrints /> },
            { id: 6, name: "Store", component: <ClassicPrints /> },
          ]}
        />
      </div>
    </>
  );
};

export default Products;
