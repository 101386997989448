import * as yup from "yup";
import { PHONE_NUMBER } from "../../../constants/regex";
import { useTranslation } from "react-i18next";

export const useAddAddressValidation = () => {
  const { t } = useTranslation();
  return yup.object().shape({
    firstName: yup
      .string()
      .min(2, t("validation.nameMinChar"))
      .max(30, t("validation.nameMaxChar"))
      .required(t("validation.nameRequired")),
    lastName: yup.string(),
    phone: yup
      .string()
      .min(9, t("validation.phoneMinChar"))
      .matches(PHONE_NUMBER, t("validation.invalidPhone"))
      .required(t("validation.phoneRequired")),
    address: yup
      .string()
      .label("Address")
      .required(t("validation.addressRequired")),
    city: yup.string().required(t("validation.cityRequired")),
    state: yup.string(),
    zip: yup.string(),
    comment: yup.string(),
    saveAddress: yup.bool(),
  });
};
