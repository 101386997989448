import { useTranslation } from "react-i18next";

const useMenuItems = () => {
  const { t } = useTranslation();

  const menuItemsData = [
    {
      id: 1,
      title: t("menu.prints"),
      url: "/#prints",
      cName: "nav-links",
    },
    // {
    //   id: 8,
    //   title: t("menu.photography"),
    //   url: "",
    //   route: "/photography",
    //   cName: "nav-links",
    // },
    {
      id: 2,
      title: t("menu.ideas"),
      url: "/#ideas",
      cName: "nav-links",
    },
    {
      id: 3,
      title: t("menu.app"),
      url: "/#app-download",
      cName: "nav-links",
    },
    {
      id: 4,
      title: t("menu.delivery"),
      url: "/#delivery",
      cName: "nav-links",
    },
    {
      id: 5,
      title: t("menu.howToUse"),
      url: "/#faq",
      cName: "nav-links",
    },
    // {
    //   id: 6,
    //   title: t("menu.aboutUs"),
    //   url: "/#about",
    //   cName: "nav-links",
    // },
    {
      id: 6,
      title: t("menu.contacts"),
      url: "/#contact",
      cName: "nav-links",
    },
  ];

  return menuItemsData;
};

export default useMenuItems;
