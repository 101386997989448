import React, { useState } from "react";
import styled from "styled-components";
import { DEVICES } from "../../../constants/layout";

const AccordionContainer = styled.div`
  width: 100%;
  margin-left: ${({ sidePadding }) => sidePadding || "20px"};
  margin-right: ${({ sidePadding }) => sidePadding || "20px"};
  border-radius: 12px;
`;

const AccordionSection = styled.div`
  width: 100%;
  background: ${({ background }) =>
    background || "linear-gradient(135deg, #ffffff, #f4f0ed)"};
  margin-bottom: 18px;
  border-radius: 12px;
`;

const AccordionTitle = styled.div`
  display: flex;
  user-select: none;
  background: ${({ background }) =>
    background || "linear-gradient(135deg, #ffffff, #f4f0ed)"};
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  border-bottom-left-radius: ${({ active }) => (active ? "0" : "12px")};
  border-bottom-right-radius: ${({ active }) => (active ? "0" : "12px")};
  justify-content: space-between;
  align-items: center;
  padding: 20px 16px;
  font-weight: 600;
  font-size: 18px;
  cursor: pointer;
`;
const AccordionContent = styled.div`
  height: ${({ active }) => (active ? "108px" : "0")};
  padding: 0px 16px;
  line-height: 32px;
  font-size: 18px;
  overflow: hidden;
  transition: height 0.3s ease-in-out;

  @media ${DEVICES.laptop} {
    height: ${({ active }) => (active ? "150px" : "0")};
  }

  @media ${DEVICES.tablet} {
    height: ${({ active }) => (active ? "340px" : "0")};
  }
`;

const ArrowIcon = styled.div`
  height: 200px;
  display: inline-block;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 5px 5px 0 5px;
  border-color: #000 transparent transparent transparent;
  transform: ${({ active }) => (active ? "rotate(180deg)" : "rotate(0deg)")};
  transition: transform 0.3s ease;
`;

const Accordion = ({ data, sidePadding }) => {
  const [activeTab, setActiveTab] = useState(null);

  const handleAccordionClick = (tabNumber) => {
    setActiveTab(activeTab === tabNumber ? null : tabNumber);
  };

  return (
    <AccordionContainer sidePadding={sidePadding}>
      {data.map((item) => (
        <AccordionSection key={item.id}>
          <AccordionTitle
            active={activeTab === item.id}
            onClick={() => handleAccordionClick(item.id)}
          >
            {item.title}
            <ArrowIcon active={activeTab === item.id} />
          </AccordionTitle>
          <AccordionContent active={activeTab === item.id}>
            <p>{item.content}</p>
          </AccordionContent>
        </AccordionSection>
      ))}
    </AccordionContainer>
  );
};

export default Accordion;
