import React from "react";
import styled from "styled-components";
import { WHITESMOKE_COLOR } from "../../../constants/colors";

const StyledParagraph = styled.p`
  font-size: ${({ fontSize }) => fontSize || ""};
  font-weight: ${({ fontWeight }) => fontWeight || ""};
  color: ${({ color }) => color || WHITESMOKE_COLOR};
  text-align: ${({ textAlign }) => textAlign || "left"};
  height: ${({ height }) => height};
  max-height: ${({ maxHeight }) => maxHeight};
`;

export const Paragraph = ({
  fontWeight,
  fontSize,
  children,
  color,
  textAlign,
  ...restProps
}) => {
  return (
    <StyledParagraph
      color={color}
      fontWeight={fontWeight}
      fontSize={fontSize}
      textAlign={textAlign}
      {...restProps}
    >
      {children}
    </StyledParagraph>
  );
};
