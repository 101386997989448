import React from "react";
import styled from "styled-components";
import { DEVICES_MIN_WIDTH } from "../../../constants/layout";

const StyledIcon = styled.i`
  @media ${DEVICES_MIN_WIDTH.laptop} {
    display: none;
  }
  font-size: ${(props) => props.size || "24px"};
  cursor: ${(props) => (props.onClick ? "pointer" : "default")};
`;

export const MenuBars = ({ onClick, iconName, size, hide, ...restProps }) => {
  return (
    <StyledIcon
      {...(onClick ? { onClick: () => onClick() } : undefined)}
      className={iconName}
      aria-hidden="true"
      size={size}
      {...restProps}
    />
  );
};
