import React from "react";
import styled from "styled-components";
import { WHITESMOKE_COLOR } from "../../../constants/colors";
import { DEVICES } from "../../../constants/layout";

const StyledHeader = styled.h2`
  @media ${DEVICES.mobileL} {
    font-size: ${({ isArmenian }) => (isArmenian ? "16px" : "26px")};
    padding: 22px 30px;
    padding: 20px;
  }
  font-size: 32px;
  text-align: center;
  font-weight: 400;
  padding: ${({ title }) => (title ? "48px 8px" : "")};
  color: ${({ theme }) => {
    if (theme === "dark") return WHITESMOKE_COLOR;
    return "default";
  }};
`;

const SectionTitle = ({ title, theme }) => {
  return (
    <StyledHeader title={title} theme={theme}>
      {title}
    </StyledHeader>
  );
};

export default SectionTitle;
