import React from "react";
import TopSection from "./TopSection";
import ImageSliderSection from "./ImageSlider";
import Services from "./Services";
import ContactSection from "./Contact";
import PricingSection from "./Pricing";
import ArtisticShowcaseSection from "./ArtisticShowcase";
import Footer from "../Home/Footer";

const Photography = () => {
  return (
    <>
      <TopSection />
      <Services />
      <ImageSliderSection />
      {/* <TestimonialsSection /> */}
      <PricingSection />
      <ArtisticShowcaseSection />
      {/* <BehindTheScenesSection /> */}
      <ContactSection />
      <Footer />
    </>
  );
};

export default Photography;
