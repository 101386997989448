const SCREEN_SIZES = {
  mobileS: "320px",
  mobileM: "375px",
  mobileL: "425px",
  mobileXL: "524px",
  tablet: "768px",
  laptop: "1024px",
  laptopM: "1200px",
  laptopL: "1440px",
  desktop: "2560px",
};

export const DEVICES = {
  mobileS: `(max-width: ${SCREEN_SIZES.mobileS})`,
  mobileM: `(max-width: ${SCREEN_SIZES.mobileM})`,
  mobileL: `(max-width: ${SCREEN_SIZES.mobileL})`,
  mobileXL: `(max-width: ${SCREEN_SIZES.mobileXL})`,
  tablet: `(max-width: ${SCREEN_SIZES.tablet})`,
  laptop: `(max-width: ${SCREEN_SIZES.laptop})`,
  laptopM: `(max-width: ${SCREEN_SIZES.laptopM})`,
  laptopL: `(max-width: ${SCREEN_SIZES.laptopL})`,
  desktop: `(max-width: ${SCREEN_SIZES.desktop})`,
};

export const DEVICES_MIN_WIDTH = {
  mobileS: `(min-width: ${SCREEN_SIZES.mobileS})`,
  mobileM: `(min-width: ${SCREEN_SIZES.mobileM})`,
  mobileL: `(min-width: ${SCREEN_SIZES.mobileL})`,
  mobileXL: `(min-width: ${SCREEN_SIZES.mobileXL})`,
  tablet: `(min-width: ${SCREEN_SIZES.tablet})`,
  laptop: `(min-width: ${SCREEN_SIZES.laptop})`,
  laptopM: `(min-width: ${SCREEN_SIZES.laptopM})`,
  laptopL: `(min-width: ${SCREEN_SIZES.laptopL})`,
  desktop: `(min-width: ${SCREEN_SIZES.desktop})`,
};

export const BOX_PADDING = "14px";
export const SIDE_PADDING = "4%";
