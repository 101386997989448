import React from "react";
import { DEVICES, SIDE_PADDING } from "../../../constants/layout";
import styled from "styled-components";

const Container = styled.div`
  margin-top: ${({ marginTop }) => marginTop};
  margin-bottom: ${({ marginBottom }) => marginBottom};

  margin-left: ${({ marginLeft }) => marginLeft || SIDE_PADDING};
  margin-right: ${({ marginRight }) => marginRight || SIDE_PADDING};
  background: rgb(255, 255, 255);
  /* background: linear-gradient(
    162deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 246, 246, 1) 50%,
    rgba(255, 221, 249, 1) 100%
  ); */
  /* background: linear-gradient(135deg, #ffffff, #f3eff3); */

  background: ${({ background }) =>
    background || "linear-gradient(135deg, #ffffff, #f4f0ed);"}; //f3eff3
  padding: ${({ padding }) => padding || "0 20px"};

  /* border-radius: 14px; */
  border-radius: 6px;
  display: flex;
  height: ${({ height }) => height || "fit-content"};
  flex-direction: ${({ flexDirection }) =>
    flexDirection || "row"}; /* Default: row for desktop view */
  align-items: center; /* Align items in the center */
  @media ${DEVICES.tablet} {
    flex-direction: ${({ flexDirectionTablet }) =>
      flexDirectionTablet || "column-reverse"};
    height: auto;
    min-height: 360px;
    padding-left: 14px;
    padding-right: 14px;
    padding-bottom: 42px;
  }
`;

const BoxedContainer = ({ children, ...restProps }) => {
  return <Container {...restProps}>{children}</Container>;
};

export default BoxedContainer;
