import React from "react";
import { Col, Nav, Row, Tab } from "react-bootstrap";

const ControlledTabs = ({ data }) => {
  return (
    <Tab.Container id="left-tabs" defaultActiveKey={data[0].id}>
      <Row>
        <Col sm={2}>
          <Nav variant="pills" className="flex-column">
            {data.map((d) => (
              <Nav.Item>
                <Nav.Link eventKey={d.id}>{d.name}</Nav.Link>
              </Nav.Item>
            ))}
          </Nav>
        </Col>
        <Col sm={8}>
          <Tab.Content>
            {data.map((d) => (
              <Tab.Pane eventKey={d.id}>{d.component}</Tab.Pane>
            ))}
          </Tab.Content>
        </Col>
      </Row>
    </Tab.Container>
  );
};

export default ControlledTabs;
