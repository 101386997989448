import React from "react";
import styled from "styled-components";
import { DEVICES } from "../../../constants/layout";

const StyledHeading = styled.h1`
  color: ${({ color }) => color};
  padding: 16px;
  margin: 0;
`;

const StyledHeading2 = styled.h2`
  font-weight: ${({ fontWeight }) => fontWeight || "500px"};
  text-align: ${({ textAlign }) => textAlign || "left"};
  color: ${({ color }) => color};
  padding: 16px;
  margin: 0;
`;

const StyledHeading4 = styled.h4`
  @media ${DEVICES.mobileL} {
    font-size: ${({ isArmenian }) => (isArmenian ? "16px" : "18px")};
  }
  color: ${({ color }) => color};
  text-align: ${({ textAlign }) => textAlign || "left"};
  padding: 16px;
  margin: 0;
`;

const StyledHeading5 = styled.h5`
  @media ${DEVICES.mobileL} {
    font-size: 18px;
  }
  text-align: ${({ textAlign }) => textAlign || "left"};
  color: ${({ color }) => color};
  padding: 6px 0;
  margin: 0;
`;

export const Heading1 = ({ children, ...restProps }) => {
  return <StyledHeading {...restProps}>{children}</StyledHeading>;
};

export const Heading2 = ({ children, ...restProps }) => {
  return <StyledHeading2 {...restProps}>{children}</StyledHeading2>;
};

export const Heading4 = ({ children, textAlign, isArmenian, ...restProps }) => {
  return (
    <StyledHeading4
      textAlign={textAlign}
      isArmenian={isArmenian}
      {...restProps}
    >
      {children}
    </StyledHeading4>
  );
};

export const Heading5 = ({ children, textAlign, ...restProps }) => {
  return (
    <StyledHeading5 textAlign={textAlign} {...restProps}>
      {children}
    </StyledHeading5>
  );
};
