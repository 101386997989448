import React from "react";
import ClassicPrints from "../Products/ClassicPrints/ClassicPrints";
import Section from "../shared/section/Section";
import { useTranslation } from "react-i18next";

const Products = () => {
  const { t } = useTranslation();
  return (
    <Section id="prints" title={t("service.exploreFeatures")}>
      <ClassicPrints />
    </Section>
  );
};

export default Products;
