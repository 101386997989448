import React, { useEffect, useState } from "react";
import { ButtonGroup, Dropdown, DropdownButton, Table } from "react-bootstrap";
import { db } from "../../../firebase";
import styled from "styled-components";
import { DEVICES } from "../../../constants/layout";
import { Icon } from "../../shared/icons/Icon";
import { ORDER_STATUS } from "../../../constants/payment";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

const DataContainer = styled.div`
  @media ${DEVICES.tablet} {
  }
`;

const getDateInfo = (date) => {
  if (!date) return "-";
  if (typeof date === "string") return date;

  return date.toDate()?.toLocaleDateString("en-US", {
    year: "numeric",
    month: "short",
    day: "numeric",
    hour: "2-digit",
    minute: "2-digit",
  });
};

const ListData = () => {
  const [loading, setLoading] = useState(false);
  const [payments, setPayments] = useState([]);
  const [orderForDelete, setOrderForDelete] = useState(null);
  const [refetch, setRefetch] = useState(false);

  useEffect(() => {
    const getData = async () => {
      setLoading(true);
      try {
        const res = await db
          .collection("payments")
          .orderBy("date", "desc")
          .limit(50)
          .get();

        if (res.empty) {
          setPayments([]);
          setLoading(false);
          return;
        }

        const ordersFromDB = res.docs.map((doc) => ({
          ...doc.data(),
          key: doc.id,
        }));

        setPayments(ordersFromDB);
        setLoading(false);
      } catch (e) {
        console.log(e);
        setLoading(false);
      }
    };

    getData();
  }, [refetch]);

  return (
    <DataContainer>
      {!!payments.length && (
        <Table responsive striped bordered hover>
          <thead>
            <tr>
              <th>Transaction ID</th>
              <th>Order ID</th>
              <th>Date</th>
              <th>Card #</th>
              <th>Amount</th>
              <th>Status</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {payments.map((order, i) => {
              const {
                key,
                transactionId,
                user_lastname,
                amount,
                date,
                orderUid,
                status,
                statusCode,
                cardNumber,
              } = order;
              return (
                <tr key={i}>
                  <td>
                    <Link to={`/payment-complete/${transactionId}`}>
                      <strong>{transactionId || "-"}</strong>
                    </Link>
                  </td>
                  <td>{orderUid || "-"}</td>
                  <td>{getDateInfo(date)}</td>
                  <td>{cardNumber || "-"}</td>
                  <td>{amount || "-"} AMD</td>
                  <td>
                    <div style={{ display: "flex" }}>
                      {statusCode && (
                        <Icon
                          margin="0 4px"
                          size="24px"
                          minSize="24px"
                          color={ORDER_STATUS[statusCode].color}
                          iconName={ORDER_STATUS[statusCode].iconName}
                        />
                      )}
                      {status || "-"}
                    </div>
                  </td>
                  <td>
                    <DropdownButton
                      variant="light"
                      as={ButtonGroup}
                      align="start"
                      title=""
                      id="dropdown-menu-align-responsive-1"
                    >
                      <Dropdown.Item>Edit</Dropdown.Item>
                      <Dropdown.Item
                        onClick={() =>
                          setOrderForDelete({
                            event: "delete",
                            id: key,
                            title: `${transactionId} ${user_lastname || ""}`,
                          })
                        }
                      >
                        Delete
                      </Dropdown.Item>
                    </DropdownButton>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      )}
    </DataContainer>
  );
};

export default ListData;
