import React, { useRef, useState } from "react";
import { Card, Form, Container, Alert } from "react-bootstrap";
import Button from "../shared/buttons/Button.jsx";
import { useDispatch } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { useAuth } from "../../contexts/AuthContext";
import { signIn } from "../../redux/actions";
import { db } from "../../firebase";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import axios from "axios";

const SignUp = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const nameRef = useRef();
  const lastNameRef = useRef();
  const emailRef = useRef();
  const phoneRef = useRef();
  const passwordRef = useRef();
  const passwordConfirmRef = useRef();
  const { signup } = useAuth();
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  async function handleSubmit(e) {
    e.preventDefault();

    if (passwordRef.current.value.length < 6) {
      return setError("Please enter more then 6 characters");
    }

    if (passwordRef.current.value !== passwordConfirmRef.current.value) {
      return setError("Passwords do not match");
    }

    try {
      setError("");
      setLoading(true);
      let response = await signup(
        emailRef.current.value,
        passwordRef.current.value
      );
      response.user
        .updateProfile({
          displayName: nameRef.current.value,
        })
        .catch(function (error) {
          toast.error(t("error.somethingWentWrong"));
        });

      db.collection("users").doc(response.user.uid).set({
        name: nameRef.current.value,
        email: emailRef.current.value,
        phone: phoneRef.current.value,
        id: response.user.uid,
        address: "",
        lastname: lastNameRef.current.value,
        city: "",
        state: "",
        zip: "",
      });

      dispatch(signIn());

      const webhookUrl = process.env.REACT_APP_SLACK_APP_USERS_WEBHOOK_URL;
      const info = `
:date: *Date*: ${new Date().toISOString()}          
:raising_hand: *Name*: ${nameRef.current.value} ${lastNameRef.current.value}
:iPhone: *Phone*: ${phoneRef.current.value}
:e-mail: *Email*: ${emailRef.current.value}`;

      const payload = `{"text":"${info}"}`;
      const data = payload;
      try {
        await axios.post(webhookUrl, data);
      } catch {
        toast.error(t("error.somethingWentWrong"), {
          position: "top-right",
          autoClose: 30000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }

      history.push("/");
    } catch {
      setError("Failed to create an account");
    }
    setLoading(false);
  }

  return (
    <>
      <Container
        className="d-flex align-items-center justify-content-center mb-4"
        style={{ minHeight: "86vh" }}
      >
        <div className="w-100 mt-4" style={{ maxWidth: "400px" }}>
          <Card>
            <Card.Body>
              <h2 className="text-center mb-4">
                {t("authentication.signUpTitle")}
              </h2>
              {error && <Alert variant="danger">{error}</Alert>}
              <Form onSubmit={handleSubmit}>
                <div className="d-flex justify-content-between">
                  <div className="w-49">
                    <Form.Group id="name" className="mb-3 pe-1">
                      <Form.Label>{t("authentication.firstName")}</Form.Label>
                      <Form.Control
                        type="text"
                        ref={nameRef}
                        placeholder={t("authentication.enterName")}
                        required
                      />
                    </Form.Group>
                  </div>
                  <div className="w-49">
                    <Form.Group id="lastName" className="mb-3 ps-1">
                      <Form.Label>{t("authentication.lastName")}</Form.Label>
                      <Form.Control
                        type="text"
                        ref={lastNameRef}
                        placeholder={t("authentication.enterLastName")}
                        required
                      />
                    </Form.Group>
                  </div>
                </div>
                <Form.Group id="phone" className="mb-3">
                  <Form.Label>{t("authentication.phone")}</Form.Label>
                  <Form.Control
                    type="phone"
                    ref={phoneRef}
                    placeholder={t("authentication.enterPhoneNumber")}
                    required
                  />
                </Form.Group>
                <Form.Group id="email" className="mb-3">
                  <Form.Label>{t("authentication.email")}</Form.Label>
                  <Form.Control
                    type="email"
                    ref={emailRef}
                    placeholder={t("authentication.enterEmail")}
                    required
                  />
                </Form.Group>
                <Form.Group id="password" className="mb-3">
                  <Form.Label>{t("authentication.password")}</Form.Label>
                  <Form.Control
                    type="password"
                    ref={passwordRef}
                    placeholder={t("authentication.enterPassword")}
                    required
                  />
                </Form.Group>
                <Form.Group id="password-confirm" className="mb-3">
                  <Form.Label>{t("authentication.confirmPassword")}</Form.Label>
                  <Form.Control
                    type="password"
                    ref={passwordConfirmRef}
                    placeholder={t("authentication.enterConfirmPassword")}
                    required
                  />
                </Form.Group>
                <Button
                  className="w-100 mt-3"
                  type="submit"
                  variant="secondary"
                  disabled={loading}
                >
                  {t("authentication.signUp")}
                </Button>
              </Form>
            </Card.Body>
          </Card>
          <div className="w-100 text-center mt-2">
            {t("authentication.alreadyHaveAccount")}{" "}
            <Link style={{ color: "blue" }} to="/signin">
              {t("authentication.login")}
            </Link>
          </div>
        </div>
      </Container>
    </>
  );
};

export default SignUp;
