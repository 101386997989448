import React from "react";
import styled from "styled-components";
import imgSlider1 from "../../assets/imgs/imageSlider/1.webp";
import imgSlider2 from "../../assets/imgs/imageSlider/2.webp";
import imgSlider3 from "../../assets/imgs/imageSlider/3.webp";
import imgSlider4 from "../../assets/imgs/imageSlider/4.webp";
import imgSlider5 from "../../assets/imgs/imageSlider/5.webp";
import imgSlider6 from "../../assets/imgs/imageSlider/6.webp";
import imgSlider7 from "../../assets/imgs/imageSlider/7.webp";
import imgSlider8 from "../../assets/imgs/imageSlider/8.webp";
// import imgSlider9 from "../../assets/imgs/imageSlider/9.webp";
// import imgSlider10 from "../../assets/imgs/imageSlider/10.webp";
// import imgSlider11 from "../../assets/imgs/imageSlider/11.webp";
// import imgSlider12 from "../../assets/imgs/imageSlider/12.webp";
import { DEVICES } from "../../constants/layout.js";
import Title from "./Title";
import { useTranslation } from "react-i18next";

const ImageSliderContainer = styled.div`
  background-color: #f5f3f5;
  padding: 40px;
  padding-top: 100px;

  @media ${DEVICES.tablet} {
    padding: 20px;
    padding-top: 60px;
  }
`;

const ImageSlider = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: -10px;

  @media ${DEVICES.tablet} {
    margin: -5px;
  }
`;

const SliderImage = styled.img`
  width: calc(25% - 20px);
  margin: 10px;
  border-radius: 4px;

  @media ${DEVICES.tablet} {
    width: calc(100% - 10px);
    margin: 5px;
  }
`;

const ImageSliderSection = () => {
  const { t } = useTranslation();

  const sliderImages = [
    imgSlider1,
    imgSlider2,
    imgSlider3,
    imgSlider4,
    imgSlider5,
    imgSlider6,
    imgSlider7,
    imgSlider8,
    // imgSlider9,
    // imgSlider10,
    // imgSlider11,
    // imgSlider12,
  ];

  return (
    <ImageSliderContainer>
      <Title text={t("photographySection.capturedMoments")} />

      <ImageSlider>
        {sliderImages.map((image, index) => (
          <SliderImage key={index} src={image} alt={`Slider Image ${index}`} />
        ))}
      </ImageSlider>
    </ImageSliderContainer>
  );
};

export default ImageSliderSection;
