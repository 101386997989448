import React from "react";
import styled from "styled-components";
import { DEVICES } from "../../constants/layout.js";

const TitleH2 = styled.h2`
  padding-bottom: 64px;
  text-align: center;
  @media ${DEVICES.tablet} {
    padding-bottom: 20px;
  }
`;

const Title = ({ text }) => {
  return <TitleH2>{text}</TitleH2>;
};

export default Title;
