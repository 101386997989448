import axios from "axios";
import { db } from "../firebase";

// const firebaseFunctionsUrl = "http://127.0.0.1:5001/yourphoto-app/us-central1";
// const firebaseFunctionsUrl = "http://127.0.0.1:5001/yourphoto-app/us-central1";
const firebaseFunctionsUrl =
  "https://us-central1-yourphoto-app.cloudfunctions.net";

export const getPaymentInfo = async (paymentId) => {
  if (!paymentId) alert("error");

  const response = await axios.post(
    `${firebaseFunctionsUrl}/getPaymentDetails`,
    { paymentId }
  );
  return response;
};

export const initPayment = async ({
  amount = 10,
  user,
  orderUid = "test - no order uid",
  orderNumber = "test - no order number",
}) => {
  try {
    if (!user.uid) return;

    const response = await axios.post(`${firebaseFunctionsUrl}/initPayment`, {
      amount,
      userId: user.uid,
    });

    // console.log("Response from function:", response.data);

    if (!response.data.paymentId) throw new Error("error processing payment");
    if (!response.data.paymentExternalUrl)
      throw new Error("error processing payment url");
    if (!response.data.transactionId)
      throw new Error("error processing transaction");

    await db
      .collection("payments")
      .doc(response.data.paymentId)
      .set({
        paymentId: response.data.paymentId,
        transactionId: String(response.data.transactionId),
        userId: user.uid,
        amount,
        date: new Date(),
        orderUid,
        orderNumber,
      });

    window.open(
      `${response.data.paymentExternalUrl}Payments/Pay?id=${response.data.paymentId}&lang=am`,
      "_blank",
      "noopener,noreferrer"
    );
    return String(response.data.transactionId);
  } catch (error) {
    console.error("Error calling function:", error);
    return false;
  }
};

export const handleConfirmPayment = async (confirmPaymentId) => {
  if (confirmPaymentId) {
    const response = await axios.post(
      `${firebaseFunctionsUrl}/confirmPayment`,
      { paymentId: confirmPaymentId }
    );
    console.log(response);
  } else {
    alert("error");
  }
};
export const handleCancelPayment = async (cancelPaymentId) => {
  if (cancelPaymentId) {
    const response = await axios.post(`${firebaseFunctionsUrl}/cancelPayment`, {
      paymentId: cancelPaymentId,
    });
    console.log(response);
  } else {
    alert("error");
  }
};
