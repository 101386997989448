import React from "react";
import styled from "styled-components";
import img1 from "../../assets/imgs/photography/photo_camera.webp"; //b32 // c23
import { DEVICES } from "../../constants/layout.js";
import { useTranslation } from "react-i18next";

const PhotographyContainer = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  background-color: #f5f3f5;
  padding: 40px;
  height: 88vh;

  @media ${DEVICES.tablet} {
    flex-direction: column-reverse;
    padding: 20px;
  }
`;

const TextContainer = styled.div`
  width: 45%;
  padding: 20px;
  max-width: 620px;

  @media ${DEVICES.tablet} {
    width: 100%;
    text-align: center;
    padding: 0;
    margin-bottom: 20px;
  }
`;

const ImageContainer = styled.div`
  width: 45%;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media ${DEVICES.tablet} {
    width: 100%;
  }
`;

const Image = styled.img`
  width: 100%;
  max-width: 720px;
  margin-bottom: 20px;
  border-radius: 8px;
`;

const TopSection = ({ onOrderClick = () => {} }) => {
  const { t } = useTranslation();

  return (
    <PhotographyContainer>
      <TextContainer>
        <h1>{t("photographySection.title")}</h1>
        <p>{t("photographySection.subtitle")}</p>
      </TextContainer>
      <ImageContainer>
        <Image src={img1} alt="Image 1" />
      </ImageContainer>
    </PhotographyContainer>
  );
};

export default TopSection;
