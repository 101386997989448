export const FOOTER_CONTACT_ITEMS = [
  {
    title: "Phone",
    url: "",
  },
  {
    title: "Prints",
    url: "#prints",
  },
  {
    title: "Reviews",
    url: "https://www.facebook.com/yourphotoprinting/reviews",
    target: "_blank",
  },
  {
    title: "About us",
    url: "#about",
  },
  {
    title: "Live chat",
    url: "https://tawk.to/chat/5c59946a6cb1ff3c14cb321a/1eu1ga0pk",
    target: "_blank",
  },
];

export const FOOTER_ICONS = [
  // {
  //   title: "Apple",
  //   icon: "fa fa-apple",
  //   url: "http://onelink.to/xgzgvs",
  //   target: "_blank",
  // },
  // {
  //   title: "Android",
  //   icon: "fa fa-android",
  //   url: "http://onelink.to/xgzgvs",
  //   target: "_blank",
  // },
  {
    title: "Facebook",
    icon: "fa fa-facebook-square",
    url: "https://www.facebook.com/yourphotoprinting",
    target: "_blank",
  },
  {
    title: "Instagram",
    icon: "fa fa-instagram",
    url: "http://instagram.com/yourphoto.am",
    target: "_blank",
  },
  {
    title: "YouTube",
    icon: "fa fa-youtube-play",
    url: "https://www.youtube.com/channel/UCX3Y2Zgd3lS-mNnG0nUdPKA",
    target: "_blank",
  },
  {
    title: "Google",
    icon: "fa fa-map-marker",
    url: "https://www.google.com/maps/place/YourPhoto+(Online+Print)+Studio/@40.1947369,44.471829,11z/data=!4m5!3m4!1s0x406abd64e868a1b1:0xea8207e999beb7e9!8m2!3d40.2021712!4d44.4760555",
    target: "_blank",
  },
];
