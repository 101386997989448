import { useAuth } from "../contexts/AuthContext";

export const usePermissions = () => {
  const { currentUser } = useAuth();

  const isAdmin =
    !!currentUser &&
    currentUser.email === process.env.REACT_APP_ADMIN_EMAIL &&
    currentUser.uid === process.env.REACT_APP_ADMIN_ID;

  return { isAdmin };
};

export default usePermissions;
