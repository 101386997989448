import React from "react";
import { Link as RouterLink } from "react-router-dom";
import styled from "styled-components";
import { PINK_RED_COLOR } from "../../../constants/colors";

const defaultBorderStyle = `2px solid ${PINK_RED_COLOR}`;

const StyledLink = styled(RouterLink)`
  &:hover {
    border-bottom: ${({ showBorderOnHover }) =>
      showBorderOnHover ? defaultBorderStyle : "none"};
    text-decoration: ${({ showBorderOnHover }) =>
      showBorderOnHover ? "none" : "default"};
  }
  text-decoration: ${({ underlined }) =>
    underlined ? "default" : "none !important"};
`;

const Link = ({ to, onClick = () => {}, children }) => {
  return (
    <StyledLink to={to} onClick={onClick}>
      {children}
    </StyledLink>
  );
};

export default Link;
