import React from "react";
import styled from "styled-components";
import BoxedContainer from "../shared/container/BoxedContainer";
import { useTranslation } from "react-i18next";
import { DEVICES } from "../../constants/layout";

const SectionContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  min-height: 260px;
  padding: 24px;
  @media ${DEVICES.tablet} {
    padding: 4px;
  }
`;
const Title = styled.h2`
  margin-bottom: 24px;
  text-align: center;
`;
const Paragraph = styled.p`
  font-size: 16px;
  text-align: center;
`;

const FeaturedText = () => {
  const { t } = useTranslation();

  return (
    <BoxedContainer flexDirection="column" id="feature-tabs" marginBottom="1px">
      <SectionContainer>
        <Title>{t("featuredText.title")}</Title>
        <Paragraph>{t("featuredText.description")}</Paragraph>
      </SectionContainer>
    </BoxedContainer>
  );
};

export default FeaturedText;
