import React from "react";
import styled from "styled-components";
import IdeasImage1 from "../../assets/imgs/ideas/images_in_yellow.webp";
import IdeasImage2 from "../../assets/imgs/ideas/image_on_stand_2.jpg";
import { Heading2 } from "../shared/styledElements/Heading";
import { DEVICES, SIDE_PADDING } from "../../constants/layout";
import { useTranslation } from "react-i18next";
import Button from "../shared/buttons/Button.jsx";
import { Paragraph } from "../shared/styledElements/Paragraph.jsx";
import { BLACK_COLOR, LIGHT_BACKGROUND_COLOR } from "../../constants/colors.js";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min.js";
import { ORDER_ROUTE } from "../../constants/routes.js";

const IdeasContainer = styled.div`
  margin-top: 120px;
  margin-bottom: 120px;
  margin-left: ${SIDE_PADDING};
  margin-right: ${SIDE_PADDING};
  display: flex;
  flex-direction: column;
  @media ${DEVICES.laptop} {
    display: flex;
    flex-direction: column;
  }
  @media ${DEVICES.tablet} {
    margin-top: 54px;
    margin-bottom: 24px;
  }
`;

const Article = styled.article`
  display: flex;
  flex-direction: row;

  @media ${DEVICES.laptop} {
    max-width: 100%;
  }
`;
const Figure1 = styled.figure`
  margin: 0;
  display: flex;
  flex-direction: row;
  @media ${DEVICES.laptop} {
    flex-direction: column;
    max-width: 100%;
  }
`;
const Figure2 = styled.figure`
  margin: 0;
  display: flex;
  flex-direction: row;
  @media ${DEVICES.laptop} {
    flex-direction: column-reverse;
    max-width: 100%;
  }
`;
const FigCaption = styled.figcaption`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 52px;
  @media ${DEVICES.tablet} {
    padding: 6px;
  }
  /* background-color: ${LIGHT_BACKGROUND_COLOR}; */
`;

const Image = styled.img`
  max-width: 50%;
  @media ${DEVICES.laptop} {
    width: 100%;
    max-width: 100%;
  }
`;

const Ideas = () => {
  const { t } = useTranslation();
  const history = useHistory();

  const onOrderCLick = (id) => history.push(`${ORDER_ROUTE}/${id}`);

  return (
    <IdeasContainer id="ideas">
      <Article>
        <Figure1>
          <Image src={IdeasImage1} alt="in the spotlight" />
          <FigCaption>
            <Heading2 textAlign="center" fontWeight="700">
              {t("ideas.spotlight")}
            </Heading2>
            <Paragraph color={BLACK_COLOR} fontSize="18px" textAlign="center">
              {t("ideas.spotlightDescription")}
            </Paragraph>
            <Button
              margin="12px"
              variant="outline-secondary"
              onClick={() => onOrderCLick(4)}
            >
              {t("welcomeSection.orderButton")}
            </Button>
          </FigCaption>
        </Figure1>
      </Article>
      <Article>
        <Figure2>
          <FigCaption>
            <Heading2 textAlign="center" fontWeight="700">
              {t("ideas.quickAndEasy")}
            </Heading2>
            <Paragraph color={BLACK_COLOR} fontSize="16px" textAlign="center">
              {t("ideas.quickAndEasyDescription")}
            </Paragraph>
            <Button
              margin="12px"
              variant="outline-secondary"
              onClick={() => onOrderCLick(1)}
            >
              {t("welcomeSection.orderButton")}
            </Button>
          </FigCaption>
          <Image src={IdeasImage2} alt="Quick and easy" />
        </Figure2>
      </Article>
    </IdeasContainer>
  );
};

export default Ideas;
